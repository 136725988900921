import React, { useContext, useMemo } from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { ReportIcon } from "../../../Icons";
import toast from "react-hot-toast";
import { UserContext } from "../../../UserContext";
export default function ReportListItem({
  type = "report",
  keys = [],
  data = [],
  currency,
  toggleIncludeExludeReport,
}) {
  const { myProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const allowToggleReport = useMemo(() => {
    return (
      myProfile?.superadmin || myProfile?.role?.some((r) => r?.name === "ADMIN")
    );
  }, [myProfile]);
  return (
    <div>
      <div className="report-data-table oddevenpattern">
        <table>
          <tbody>
            <tr className="header">
              {keys.map((key, index) => (
                <td key={index}>{key}</td>
              ))}
            </tr>
            {data.map((item, index) => (
              <tr className="data" key={index}>
                <td
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item?._id) {
                      navigate(`/report/${item?._id}`);
                    }
                  }}
                >
                  {item?.controlvariant}{" "}
                  {item?.isMabTest && (
                    <Button className="smart-pill xsmall active">
                      MAB Test
                    </Button>
                  )}
                </td>
                <td>{!item?.isMabTest ? item?.conclusion : "NA"}</td>
                <td>{item?.segment}</td>

                <td>{item?.duration}</td>
                <td>
                  {!item?.isMabTest ? item?.changeInConversionRate : "NA"}
                </td>

                <td>{item?.additionalRevenue} </td>
                <td>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (item?._id) {
                        navigate(`/report/${item?._id}`);
                      }
                    }}
                    text={`More Details`}
                    className={`smart-button small uppercase full-width`}
                    icon={<ReportIcon />}
                  />
                  {allowToggleReport && (
                    <div className="flex justify-center default-margin-top">
                      <div className="switch-with-label inline-flex align-center">
                        <div className="switch-btn">
                          <input
                            onChange={(e) => {
                              toggleIncludeExludeReport(
                                item?._id,
                                e.target.checked
                              );
                              toast.success(
                                "Please refresh the page to see changes on Performance Overview."
                              );
                            }}
                            type="checkbox"
                            defaultChecked={
                              item?.excludedFromCalculations || false
                            }
                            className="checkbox"
                            id={`${item?._id}`}
                          />
                          <label
                            htmlFor={`${item?._id}`}
                            className="checkbox-label"
                          >
                            <div>yes</div>
                            <div>no</div>
                            <span className="ball"></span>
                          </label>
                        </div>
                        <span>Exclude Report </span>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
