import { Button } from "../Button/Button";
import "./Pagination.scss";
export const Pagination = ({ page, setPage, size, totalcount }) => {
  return (
    <ul className="pagination default-margin-top flex align-center justify-center default-column-gap">
      <li className="">
        <Button
          className="smart-button medium"
          onClick={(_) => setPage(page - 1)}
          disabled={page === 1 ? true : false}
        >
          <span style={{ transform: "rotate(180deg)" }}>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.59106 9.3485L4.93958 5L0.59106 0.651489L0.0605602 1.18149L3.87908 5L0.0605602 8.8185L0.59106 9.3485Z"
                fill="#fff"
              />
            </svg>
          </span>
          Prev
        </Button>
      </li>
      <li className="">
        <Button
          className="smart-button medium"
          onClick={(_) => setPage(page + 1)}
          disabled={totalcount > page * size ? false : true}
        >
          Next
          <span>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.59106 9.3485L4.93958 5L0.59106 0.651489L0.0605602 1.18149L3.87908 5L0.0605602 8.8185L0.59106 9.3485Z"
                fill="#fff"
              />
            </svg>
          </span>
        </Button>
      </li>
    </ul>
  );
};
