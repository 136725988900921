// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sharable-report-detail .report-name-heading {
  row-gap: var(--default-row-gap);
}
.sharable-report-detail h1.m-0 {
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sharable-report-detail .conclusion-factors-wrapper {
  margin: var(--default-margin-bottom) auto;
  column-gap: 1.06%;
  white-space: nowrap;
  row-gap: var(--default-row-gap);
}`, "",{"version":3,"sources":["webpack://./src/Views/Pages/ReportDetails/ReportDetails.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;AAAR;AAEI;EACI,kBAAA;EACA,gBAAA;EACA,uBAAA;AAAR;AAEI;EACI,yCAAA;EACA,iBAAA;EACA,mBAAA;EACA,+BAAA;AAAR","sourcesContent":[".sharable-report-detail{\n    .report-name-heading{\n        row-gap: var(--default-row-gap);\n    }\n    h1.m-0{\n        margin-bottom: 0px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n    .conclusion-factors-wrapper{\n        margin: var(--default-margin-bottom) auto;\n        column-gap: 1.06%;\n        white-space: nowrap;\n        row-gap: var(--default-row-gap);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
