import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Search from "../../Components/Search/Search";
import TestFilterViewMode from "../../Components/TestFilterViewMode/TestFilterViewMode";
import ReportCard from "../../Components/ReportCard/ReportCard";
import { useLazyQuery } from "@apollo/client";
import { GETAUDIENCEINSIGHTSV2 } from "../../../Graphql/Queries";
import { UserContext } from "../../../UserContext";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";

const initialState = {
  searchText: "",
  testFilter: "all",
};

function reducer(state, action) {
  switch (action.type) {
    case "searchText":
      return { ...state, searchText: action.payload };
    case "testFilter":
      return {
        ...state,
        testFilter: action.payload,
      };
    default:
      return "Unrecognized command";
  }
}

export const Insights = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: clientStoreState } = useContext(UserContext);
  const [viewMode, setViewMode] = useState("list");
  const [getaudienceinsights] = useLazyQuery(GETAUDIENCEINSIGHTSV2);
  const [data, setData] = useState({
    loading: true,
  });
  const getInsightsData = useCallback(async () => {
    try {
      const {
        data: { audienceInsightsV2 },
        loading,
      } = await getaudienceinsights({
        variables: {
          client: clientStoreState.client,
          viewId: clientStoreState.store,
          filters: [
            {
              conclusion:
                state.testFilter !== "all" ? [state.testFilter] : null,
            },
            { name: state.searchText !== "" ? state.searchText : null },
          ],
        },
      });
      setData({
        ...audienceInsightsV2,
        loading,
      });
    } catch (error) {
      console.log(error);
    }
  }, [state, clientStoreState]);

  useEffect(() => {
    getInsightsData();
    return () => {};
  }, [getInsightsData]);
  if (data?.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="insights section-pd">
      <h1>Audience Insights</h1>
      <Search
        dispatch={dispatch}
        initialValue={state.searchText}
        placeholder="Search the Test"
        bindOnClick={true}
      />
      <TestFilterViewMode
        dispatch={dispatch}
        state={state}
        viewMode={viewMode}
        setViewMode={setViewMode}
        filtersCount={{}}
      />
      <div className="reports-list-wrapper">
        <div className="reports-list-inner flex flex-wrap justify-space-between">
          {data?.data?.results?.map((insight, index) => (
            <ReportCard type="insight" data={insight} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
