import { Bar } from "react-chartjs-2";
import "./BarChartComponent.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Grid } from "../../../Icons";
import NoData from "../NoData/NoData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChartComponent({
  title = "",
  data,
  colors,
  probabilityOfWinner,
  ...props
}) {
  // const options = {
  //   type: "bar",
  //   indexAxis: "y",
  // };
  if (
    probabilityOfWinner?.labels?.length === 0 ||
    probabilityOfWinner?.data?.length === 0
  ) {
    return (
      <div {...props}>
        <NoData
          title="No Data Found"
          details="No Graph data found for this module."
        />
      </div>
    );
  }
  return (
    <div className="bar-chart-wrapper section-pd border-bottom" {...props}>
      <div className="bar-chart-inner">
        {title && (
          <div className="chart-heading">
            <h2> {title}</h2>
          </div>
        )}
        {/* <div className="chart">
          <Bar options={options} data={data} />
        </div> */}
        <div className="bar-chart-grid">
          <Grid />
          {data?.labels?.map((label, index) => (
            <div className="bar-chart-grid-box" key={index}>
              <div className="barvariant">{label}</div>
              <div className="bar-wrapper">
                <div
                  className="bar"
                  style={{
                    width: `${data?.datasets[0]?.data[index]}%`,
                    background: `linear-gradient(90deg, rgba(${colors[index]}, 0.3), rgba(${colors[index]}, 1))`,
                  }}
                ></div>
              </div>
              <div className="barvalue">{data?.datasets[0]?.data[index]} %</div>
            </div>
          ))}
          <div className="bar-chart-grid-box barchart-points-wrapper">
            <div className="barvariant"></div>
            <div className="bar-wrapper">
              <div className="barchart-points">
                {Array.from(Array(11)).map((_, i) => (
                  <span key={i}>{i * 10}</span>
                ))}
              </div>
            </div>
            <div
              className="barvalue"
              style={{
                opacity: 0,
              }}
            >
              66.18%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
