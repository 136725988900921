import { useContext } from "react";
import PerformanceOverview from "./Components/PerformanceOverview/PerformanceOverview";
import TestsOverview from "./Components/TestsOverview/TestsOverview";
import ReportsList from "./Components/ReportsList/ReportsList";
import { UserContext } from "../../../UserContext";
import AddRevPerMonth from "./Components/AddRevPerMonth/AddRevPerMonth";

export const Dashboard = () => {
  const { state } = useContext(UserContext);
  return (
    <div>
      <PerformanceOverview client={state.client} store={state.store} />
      <AddRevPerMonth
        client={state.client}
        store={state.store}
        theme={state?.theme}
      />

      <TestsOverview client={state.client} store={state.store} />

      <ReportsList client={state.client} store={state.store} />
    </div>
  );
};
