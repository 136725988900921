import { Logo } from "../../../../Icons";
import Input from "../../../Components/Input/Input";
import "./index.scss";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../../../Graphql/Mutations";
import { ValidateEmail } from "../../../../utilities";
import { Button } from "../../../Components/Button/Button";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
export default function LoginComponent() {
  const [login, { reset }] = useMutation(LOGIN);
  const loginAttempt = async (_) => {
    _.preventDefault();
    const data = new FormData(_.target);
    const email = String(data.get("email").toLowerCase().trim());
    const password = data.get("password");
    if (!ValidateEmail(email)) {
      // promtError("Invalid Email");
      toast.error("Invalid Email");
      return;
    }
    // if (password.length < 6) {
    //   toast.error("Password must be at least 6 characters");
    //   // promtError("Password must be at least 6 characters");
    //   return;
    // }
    try {
      const { data } = await login({
        variables: {
          email,
          password,
        },
      });
      if (data) {
        window.location.href = data?.loginUser?.redirectUrl;
      }
    } catch (error) {
      reset();
      toast.error(error.message);
    }
  };
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-card">
          <Logo />

          <form onSubmit={loginAttempt}>
            <Input
              type="email"
              placeholder="Enter your Email"
              label="Email"
              name="email"
              autoComplete="off"
              autoCapitalize="off"
              icon={null}
            />
            <Input component={true}>
              <div className="field-wrapper">
                <label> Password</label>
                <div className="input-wrapper">
                  {/* <span>Icon</span> */}
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    autoComplete="off"
                    name="password"
                    autoCapitalize="off"
                  />
                  {/* <span>Icon</span> */}
                </div>
                <div className="fogotpassword">
                  <Link to="/login/reset-password"> Forgot Password? </Link>
                </div>
              </div>
            </Input>
            <Button
              className="full-width smart-button big active "
              type="submit"
            >
              Log in
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
