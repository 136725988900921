import { gql } from "@apollo/client";

export const MYPROFILE = gql`
  query MyProfile {
    myProfile {
      _id
      name
      email
      superadmin
      client {
        _id
        name
        currency
        logo
        bqClientId
        defaultDataSet
        gaproperties {
          viewId
          propertyname
          platform
        }
      }
      role {
        _id
        name
      }
      twoFactor
    }
  }
`;

export const TWOFACTORQRCODE = gql`
  query Query {
    generateQRCode {
      qrString
      enabled
    }
    myProfile {
      _id
      client {
        logo
        name
      }
    }
  }
`;

export const GETCVRREPORT = gql`
  query Query($clientId: ID) {
    getCVRReport(clientId: $clientId) {
      _id
      reportLink
      client {
        _id
        name
      }
    }
  }
`;

export const GETADDREVENUE = gql`
  query Query(
    $client: ID
    $viewId: String
    $dateRange: dateRange
    $type: String
  ) {
    getrevenueV2(
      client: $client
      viewId: $viewId
      dateRange: $dateRange
      type: $type
    )
  }
`;

export const CLIENTSTATISTICS = gql`
  query Query($client: ID!, $viewId: String) {
    clientTestsStatisticsV2(client: $client, viewId: $viewId)
  }
`;

export const GETREPORTSV2 = gql`
  query getReportsV2(
    $client: ID
    $viewId: String
    $page: Int
    $size: Int
    $filters: JSON
  ) {
    getReportsV2(
      client: $client
      viewId: $viewId
      page: $page
      size: $size
      filters: $filters
    )
  }
`;

export const GETCLIENTLINKSANDSHEETS = gql`
  query GetClientLinks($client: ID, $includePrivateDocs: Boolean) {
    getClientLinks(client: $client, includePrivateDocs: $includePrivateDocs) {
      page
      size
      total
      links {
        _id
        title
        link
      }
    }
  }
`;

export const GETAUDIENCEINSIGHTSV2 = gql`
  query Query($client: ID!, $viewId: String, $filters: JSON) {
    audienceInsightsV2(client: $client, viewId: $viewId, filters: $filters)
  }
`;

export const GETREPORTV2 = gql`
  query Query($getReportV2Id: ID!) {
    getReportV2(id: $getReportV2Id)
  }
`;

export const GETTESTSV2 = gql`
  query GetTestsV2($page: Int, $size: Int, $filters: JSON) {
    getTestsV2(page: $page, size: $size, filters: $filters) {
      page
      size
      tests {
        _id
        name
        livedate
        controlimage
        variationimage
        property
      }
      total
      other
    }
  }
`;

export const GETHISTORIES = gql`
  query Query($client: ID!, $viewId: String!, $test: ID!, $filters: JSON) {
    getCachedAnalyticsData(
      client: $client
      view_id: $viewId
      test: $test
      filters: $filters
    )
  }
`;

export const GETBIGQUERYDATASETS = gql`
  query Query($projectId: String!) {
    getBiQueryDataSets(projectId: $projectId)
  }
`;

export const GETBUSINESSCASEVALIDATIONDATA = gql`
  query Query($client: ID!, $viewId: String, $filters: JSON) {
    getBusinessCaseValidationDataV2(
      client: $client
      viewId: $viewId
      filters: $filters
    )
  }
`;

export const CHECKTOKEN = gql`
  query Query($token: String!) {
    checkTokenV2(token: $token)
  }
`;

export const GETCLIENTSFORTABLE = gql`
  query Query(
    $clientFilters: JSON
    $page: Int
    $size: Int
    $testStatus: String
  ) {
    clients(
      clientFilters: $clientFilters
      page: $page
      size: $size
      testStatus: $testStatus
    ) {
      total
      page
      size
      clients {
        _id
        name
        active
        viewId
        currency
        tier {
          _id
          name
          colorSwatch
        }
        tests {
          status
        }
        bqClientId
        defaultDataSet
      }
    }
  }
`;
