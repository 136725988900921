import { useEffect, useState } from "react";
import { Logo } from "../../../Icons";
import Input from "../../Components/Input/Input";
import { Button } from "../../Components/Button/Button";
import { useMutation } from "@apollo/client";
import { CHANGERESETPASSWORD, RESETPASSWORD } from "../../../Graphql/Mutations";
import { ValidateEmail } from "../../../utilities";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword() {
  const { userId, token } = useParams();
  const navigate = useNavigate();
  const [resetpassword] = useMutation(RESETPASSWORD);
  const [changeresetpassword] = useMutation(CHANGERESETPASSWORD);
  const [linkSent, setLinkSent] = useState(false);
  useEffect(() => {
    localStorage.removeItem("codebase_token");
    return () => {};
  }, []);
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-card">
          <Logo />

          {userId && token ? (
            <>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const form = e.target;
                  const password = form.password.value?.trim();
                  const confirmPassword = form.confirmPassword.value?.trim();
                  if (password !== confirmPassword) {
                    toast.error("Passwords do not match");
                    return;
                  }
                  try {
                    await changeresetpassword({
                      variables: {
                        password,
                        userId,
                        token,
                      },
                    });
                    toast.success("Password changed successfully");
                    navigate("/login");
                  } catch (error) {
                    toast.error(error.message);
                  }
                }}
              >
                <Input component={true}>
                  <div className="field-wrapper">
                    <label> New Password</label>
                    <div className="input-wrapper">
                      {/* <span>Icon</span> */}
                      <input
                        type="text"
                        placeholder="Enter new Password"
                        autoComplete="off"
                        name="password"
                        autoCapitalize="off"
                      />
                      {/* <span>Icon</span> */}
                    </div>
                  </div>
                </Input>
                <Input component={true}>
                  <div className="field-wrapper">
                    <label> Confirm Password</label>
                    <div className="input-wrapper">
                      {/* <span>Icon</span> */}
                      <input
                        type="password"
                        placeholder="Enter new Password"
                        autoComplete="off"
                        name="confirmPassword"
                        autoCapitalize="off"
                      />
                      {/* <span>Icon</span> */}
                    </div>
                  </div>
                </Input>
                <Button
                  className="full-width smart-button big active "
                  type="submit"
                >
                  Change Password
                </Button>
              </form>
            </>
          ) : (
            <>
              {!linkSent ? (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const form = e.target;
                    const email = form.email.value?.trim()?.toLowerCase();
                    if (!ValidateEmail(email)) {
                      toast.error("Invalid email address");
                      return;
                    }
                    try {
                      await resetpassword({
                        variables: {
                          email: email,
                          baseUrl: window.location.origin,
                        },
                      });
                      toast.success(
                        "Password reset link sent. Please check your email."
                      );
                      setLinkSent(true);
                    } catch (error) {
                      toast.error(error.message);
                    }
                  }}
                >
                  <Input
                    type="email"
                    placeholder="Enter your Email"
                    label="Email"
                    name="email"
                    autoComplete="off"
                    autoCapitalize="off"
                    icon={null}
                  />
                  <Button
                    className="full-width smart-button big active "
                    type="submit"
                  >
                    Send Reset Link
                  </Button>
                </form>
              ) : (
                <Button
                  disabled
                  className="full-width smart-button big active "
                  type="submit"
                >
                  Please check your email
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
