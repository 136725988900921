import "./Styles/Reset.scss";
import "./Styles/App.scss";
import RoutesLinks from "./RoutesManager/RoutesLinks";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./Views/Components/Loader/Loader";
import ScrollToTop from "./Views/Components/ScrollTopTop/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <RoutesLinks />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
