import NoData from "../../Components/NoData/NoData";
import { useLazyQuery } from "@apollo/client";
import { GETCVRREPORT } from "../../../Graphql/Queries";
import { useCallback, useContext, useEffect, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import { UserContext } from "../../../UserContext";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import { Button } from "../../Components/Button/Button";

const reportFilters = [
  {
    title: "CVR Report",
    id: "cvr-report",
  },
  {
    title: "Airtable View",
    id: "airtable-embed",
  },
];

export const CVRReport = () => {
  const { state } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [defaultView, setDefaultView] = useState("cvr-report");
  const [getcvrdata] = useLazyQuery(GETCVRREPORT);

  const fetchData = useCallback(
    async (client) => {
      if (!client) return;
      try {
        const { data, loading } = await getcvrdata({
          fetchPolicy: "network-only",
          variables: {
            clientId: client,
          },
        });
        setData({
          link: data?.getCVRReport?.reportLink,
          airtableEmbedLink: data?.getCVRReport?.airtableEmbedLink,
          loading,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [getcvrdata]
  );

  useEffect(() => {
    fetchData(state.client);
    return () => {
      setData(null);
    };
  }, [state, fetchData]);
  if (!state.client || data?.link === undefined)
    return (
      <NoData
        title="There was some error fetching details"
        details="Please retry wih your request or connect with the admin if the issue persists"
      />
    );
  if (!data || data.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="insights section-pd">
      <div className="results-filter-wrapper flex align-center justify-space-between flex-wrap">
        <div className="flex align-center">
          <div className="inline-flex">
            {reportFilters?.map((filter) => (
              <Button
                onClick={() => setDefaultView(filter.id)}
                key={filter.id}
                className={`smart-button big ${
                  filter?.id === defaultView ? `active` : ``
                }`}
              >
                {filter.title}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div
        className="cvr-iframe-container"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            `<iframe
              src=${
                defaultView === "cvr-report"
                  ? data.link
                  : data.airtableEmbedLink
              }
              title="Looker Studio"
              width="100%"
              height="1080px"
              sandbox="allow-scripts allow-same-origin"
            ></iframe>`,
            {
              ALLOWED_TAGS: ["iframe"],
            }
          ),
        }}
      />

      {/* {defaultView === "cvr-report" ? (
        <div
          className="cvr-iframe-container"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              `<iframe
              src=${data.link}
              title="Looker Studio"
              width="100%"
              height="650px"
              sandbox="allow-scripts allow-same-origin"
            ></iframe>`,
              {
                ALLOWED_TAGS: ["iframe"],
              }
            ),
          }}
        />
      ) : (
        <div>
          <iframe
            class="airtable-embed neon-shadow"
            src={data?.airtableEmbedLink}
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="650px"
            style={{
              background: "transparent",
            }}
          ></iframe>
        </div>
      )} */}
    </div>
  );
};
