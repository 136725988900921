import { Button } from "../../../../Components/Button/Button";
import ReportCard from "../../../../Components/ReportCard/ReportCard";
import "./SequentialResults.scss";
export default function SequentialResults({ results = null }) {
  if (!results) return null;
  return (
    <div className="sequential-results-wrapper section-pd border-bottom">
      <h2>Sequential AB Test Results</h2>
      <div className="sequential-results inline-flex align-center justify-center">
        <div>
          Confidence: <span> {(1 - results?.values?.alpha) * 100}% </span>
        </div>
        <div>
          Power: <span> {(1 - results?.values.beta) * 100}%</span>
        </div>
        <div>
          Min. Detectable effect:{" "}
          <span> {(1 - results?.values.deltaMin) * 100}%</span>
        </div>
        <div>
          Max. Sample Size: <span> {results?.values?.nMax}</span>
        </div>
      </div>
      <div className="flex align-center sequential-cards-wrapper">
        <ReportCard
          type="sq"
          data={{
            title: "Control Group Success",
            value: results?.controlGroupSuccess,
          }}
        />
        <ReportCard
          type="sq"
          data={{
            title: "Variant Group Success",
            value: results?.variantGroupSuccess,
          }}
        />
        <ReportCard
          type="sq"
          data={{
            title: "Test Result",
            value: results?.testResult,
            check: false,
          }}
        />
        <ReportCard
          type="sq"
          data={{
            title: "Significant Percentage",
            value: results?.percentageResult,
            postfix: "%",
          }}
        />
      </div>
    </div>
  );
}
