// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-calendar-wrapper {
  position: relative;
}
.date-range-calendar-wrapper .date-range-calendar-inner {
  position: absolute;
  right: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 2px;
}
.date-range-calendar-wrapper .date-range-calendar-inner .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/DateRangeComponent/DateRangeComponent.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;AAER;AAAY;EACI,aAAA;AAEhB","sourcesContent":[".date-range-calendar-wrapper{\n    position: relative;\n    .date-range-calendar-inner{\n        position: absolute;\n        right: 0;\n        z-index: 2;\n        overflow: hidden;\n        border-radius: 2px;\n        .rdrDateRangePickerWrapper{\n            .rdrDefinedRangesWrapper{\n                display: none;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
