// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nodata-wrapper .nodata-inner {
  padding: var(--value12) var(--value24);
  border-radius: 4px;
  border: 1px solid #FF1212;
  background: rgba(255, 18, 18, 0.16);
  margin-top: var(--default-margin-bottom);
  margin-bottom: var(--default-margin-bottom);
}
.nodata-wrapper .nodata-inner .nodata-text h3 {
  color: var(--desc-color);
  margin-bottom: 12px;
  line-height: normal;
  font-size: var(--value24);
}
.nodata-wrapper .nodata-inner .nodata-text p {
  font-size: var(--xl-fontsize);
  font-weight: var(--font-400);
  margin-bottom: var(--value12);
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/NoData/NoData.scss"],"names":[],"mappings":"AACI;EACI,sCAAA;EACA,kBAAA;EACA,yBAAA;EACA,mCAAA;EACA,wCAAA;EACA,2CAAA;AAAR;AAEY;EACI,wBAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;AAAhB;AAEY;EACI,6BAAA;EACA,4BAAA;EACA,6BAAA;AAAhB","sourcesContent":[".nodata-wrapper{\n    .nodata-inner{\n        padding: var(--value12) var(--value24);\n        border-radius: 4px;\n        border: 1px solid #FF1212;\n        background: rgba(255, 18, 18, 0.16);\n        margin-top: var(--default-margin-bottom);\n        margin-bottom: var(--default-margin-bottom);\n        .nodata-text{\n            h3{\n                color: var(--desc-color);\n                margin-bottom: 12px;\n                line-height: normal;\n                font-size: var(--value24);\n            }\n            p{\n                font-size: var(--xl-fontsize);\n                font-weight: var(--font-400);\n                margin-bottom: var(--value12);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
