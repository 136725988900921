export default function Loader() {
  return (
    <>
      <h1
        style={{
          color: "white",
        }}
      >
        Loading
      </h1>
    </>
  );
}
