import React, { useEffect, useState } from "react";
import "./TestsOverview.scss";
import { useLazyQuery } from "@apollo/client";
import {
  CLIENTSTATISTICS,
  GETBUSINESSCASEVALIDATIONDATA,
} from "../../../../../Graphql/Queries";
import ContentSkeleton from "../../../../Components/Skeletons/ContentSkeleton";
import PerformanceOverview from "../PerformanceOverview/PerformanceOverview";
import { ArrowNextIcon, ArrowUpIcon, CloseIcon } from "../../../../../Icons";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../../Components/Button/Button";
import BusinessCaseData from "../BusinessCaseData/BusinessCaseData";
import { CURRENCYSYMBOLSMAP } from "../../../../../constants";
export default function TestsOverview({
  heading = "Test Overview",
  client,
  store,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getclientbusinesscasedata] = useLazyQuery(
    GETBUSINESSCASEVALIDATIONDATA
  );
  const [overview, setOverview] = React.useState(true);
  const [getclientstatistics] = useLazyQuery(CLIENTSTATISTICS);
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      setData((data) => ({
        ...data,
        loading: true,
        businessCaseValidationDataLoading: true,
      }));
      try {
        const { data, loading } = await getclientstatistics({
          variables: {
            client,
            viewId: store,
          },
        });
        setData((oldData) => ({
          ...oldData,
          ...data?.clientTestsStatisticsV2,
          loading,
        }));

        const {
          data: clientBusinessData,
          loading: clientBusinessDataLoading,
          error,
        } = await getclientbusinesscasedata({
          variables: {
            client,
            viewId: store,
            filters: [
              {
                sheetData: searchParams.get("sheetData") ?? "false",
              },
            ],
          },
        });
        setData((oldData) => ({
          ...oldData,
          businessCaseValidationDataLoading: clientBusinessDataLoading,
          getBusinessCaseValidationDataV2: {
            ...clientBusinessData?.getBusinessCaseValidationDataV2,
          },
          businessCaseValidationDataError: error,
        }));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [client, store, getclientstatistics]);

  const InsightCard = ({ label = "", value = "", loading }) => {
    if (loading)
      return (
        <div className="default-border full-width flex flex-column">
          <div className="card-top">
            <p> Loading...</p>
          </div>
          <div className="card-bottom">....</div>
        </div>
      );
    return (
      <div className="default-border full-width flex flex-column">
        <div className="card-top">
          <p> {label}</p>
        </div>
        <div className="card-bottom">{value}</div>
      </div>
    );
  };
  const PerformanceCard = ({
    changeIn = null,
    title = "Additional Revenue after Implementation",
    value = "17",
    prefix = "",
    postfix = "",
    active = false,
    loading = true,
  }) => {
    if (loading)
      return (
        <div className={`p-card card-padding`}>
          <div className="p-card-inner justify-space-between full-height">
            <div className="p-card-top inline-flex align-center">
              <p> Loading...</p>
            </div>
            <div className="p-card-bottom inline-flex align-center flex-row-reverse justify-end">
              <span className="value"> ..... </span>
            </div>
          </div>
        </div>
      );
    return (
      <div
        className={`p-card card-padding ${active ? `active neon-shadow` : ``}`}
      >
        <div className="p-card-inner justify-space-between full-height">
          <div className="p-card-top inline-flex align-center">
            <span className="change-in">
              <ArrowUpIcon />
            </span>

            <p> {title}</p>
          </div>
          <div className="p-card-bottom inline-flex align-center flex-row-reverse justify-end">
            {title === "Return on Invest" && value <= 0 ? (
              <>
                <span> - </span> <span> to be updated</span>
              </>
            ) : (
              <>
                <span className="prepost">{postfix}</span>
                <span className="value"> {value} </span>
                <span className="prepost">{prefix}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const SuccessRate = ({ label = "", value = "", symbol = null }) => (
    <div className="success-rt flex flex-column full-height justify-space-between">
      <div className="card-top">
        <p> {label}:</p>
        <span>
          {" "}
          {value}
          {symbol}
        </span>
      </div>
      <div className="card-bottom">
        <p>of all tests were successful and adviced to be implemented</p>
      </div>
    </div>
  );

  // if (!data || data.loading)
  //   return (
  //     <div>
  //       <ContentSkeleton />
  //     </div>
  //   );

  // console.log(data);
  return (
    <div className="section-pd border-bottom">
      <div className="conversion-performance test-performance-overview-wrapper flex justify-space-between default-column-gap default-row-gap flex-wrap">
        <div className="flex flex-column">
          <h1> Performance Overview:</h1>
          <div className="business-performance-overview-wrapper flex justify-space-between p-card-flex full-height">
            <PerformanceCard
              loading={
                data?.businessCaseValidationDataLoading ||
                !data?.getBusinessCaseValidationDataV2?.topLineMetrics
              }
              {...{
                title: "Total Additional Revenue",
                value:
                  data?.getBusinessCaseValidationDataV2?.topLineMetrics?.totalAddRevenue?.toLocaleString(
                    "en-US"
                  ),
                prefix:
                  CURRENCYSYMBOLSMAP[
                    data?.getBusinessCaseValidationDataV2?.currencyCode
                  ],
              }}
              active={true}
            />
            <PerformanceCard
              loading={
                data?.businessCaseValidationDataLoading ||
                !data?.getBusinessCaseValidationDataV2?.topLineMetrics
              }
              {...{
                title: "Additional transactions due to CRO efforts",
                value:
                  data?.getBusinessCaseValidationDataV2?.topLineMetrics?.additionalTransactionsDueToCROEfforts?.toLocaleString(
                    "en-US"
                  ),
                symbol: "",
              }}
              // active={true}
            />
          </div>
        </div>
        <div className="flex flex-column">
          <h1> Tests Overview:</h1>
          <div className="sucess-rate-insights-wrapper default-border full-width default-padding flex justify-space-between full-height">
            <SuccessRate
              label="Success Rate"
              value={data?.statistics?.successRate.value}
              symbol={data?.statistics?.successRate.symbol}
            />
            <div className="test-insights flex flex-column justify-space-between">
              <InsightCard
                {...{
                  label: data?.statistics?.ended.label,
                  value: data?.statistics?.ended.value,
                  symbol: "",
                }}
                loading={data?.loading}
              />
              <InsightCard
                {...{
                  label: data?.statistics?.live.label,
                  value: data?.statistics?.live.value,
                  symbol: "",
                }}
                loading={data?.loading}
              />
              <InsightCard
                {...{
                  label: data?.statistics?.draft.label,
                  value: data?.statistics?.draft.value,
                  symbol: "",
                }}
                loading={data?.loading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="performance-cta default-margin-top flex">
        <Button
          className="smart-button medium"
          disabled={data?.businessCaseValidationDataLoading}
          style={
            data?.getBusinessCaseValidationDataV2?.months?.length > 0
              ? {}
              : { display: "none" }
          }
          onClick={() => {
            setOverview(!overview);
          }}
          text={overview ? `See Details` : `Close Performance Overview`}
          icon={overview ? <ArrowNextIcon fill="#fff" /> : <CloseIcon />}
        />
      </div>
      <div
        style={
          overview
            ? {
                display: "none",
              }
            : {}
        }
      >
        <BusinessCaseData {...data?.getBusinessCaseValidationDataV2} />
      </div>
    </div>
  );
  // return (
  //   <div className="section-pd border-bottom">
  //     <h1>{heading}</h1>
  //     <div className="t-card-flex flex justify-space-between flex-wrap">
  //       <PerformanceOverview
  //         data={data}
  //         loading={data?.businessCaseValidationDataLoading}
  //         error={data?.businessCaseValidationDataError}
  //         overview={overview}
  //         setOverview={setOverview}
  //       />
  //       <div className="conversion-performance">
  //         <div className="p-card-flex flex">
  //           <PerformanceCard
  //             {...{
  //               title: data?.statistics?.successRate.label,
  //               value: data?.statistics?.successRate.value,
  //               symbol: data?.statistics?.successRate.symbol,
  //             }}
  //             loading={data?.loading}
  //           />
  //           <PerformanceCard
  //             {...{
  //               title: data?.statistics?.avrupliftonwinningtests.label,
  //               value: data?.statistics?.avrupliftonwinningtests.value,
  //               symbol: "%",
  //             }}
  //             loading={data?.loading}
  //           />
  //         </div>
  //       </div>

  //       <div className="conversion-performance">
  //         <div>
  //           <div className="p-card-flex flex">
  //             <PerformanceCard
  //               {...{
  //                 title: data?.statistics?.ended.label,
  //                 value: data?.statistics?.ended.value,
  //                 symbol: "",
  //               }}
  //               loading={data?.loading}
  //             />

  //             <PerformanceCard
  //               {...{
  //                 title: data?.statistics?.live.label,
  //                 value: data?.statistics?.live.value,
  //                 symbol: "",
  //               }}
  //               loading={data?.loading}
  //             />
  //             <PerformanceCard
  //               {...{
  //                 title: data?.statistics?.draft.label,
  //                 value: data?.statistics?.draft.value,
  //                 symbol: "",
  //               }}
  //               loading={data?.loading}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       {/* </div> */}
  //     </div>
  //     <div
  //       style={
  //         overview
  //           ? {
  //               display: "none",
  //             }
  //           : {}
  //       }
  //     >
  //       <div className="performance-cta margin-top-auto">
  //         <Button
  //           className="smart-button medium"
  //           text={`Close Performance Overview`}
  //           // disabled={loading}
  //           onClick={() => setOverview(!overview)}
  //           icon={
  //             <div
  //               className="inline-flex"
  //               style={{
  //                 transform: "rotate(180deg)",
  //               }}
  //             >
  //               <ArrowNextIcon fill="#fff" />
  //             </div>
  //           }
  //         />
  //       </div>
  //       <BusinessCaseData {...data?.getBusinessCaseValidationDataV2} />
  //     </div>
  //   </div>
  // );
}
