import React from "react";
export default function InsightsList({ keys = [], data = [], currency }) {
  return (
    <div>
      <div className="report-data-table oddevenpattern">
        <table>
          <tbody>
            <tr className="header">
              {keys.map((key, index) => (
                <td key={index}>{key}</td>
              ))}
            </tr>
            {data.map((item, index) => (
              <tr className="data" key={index}>
                <td>{item?.test} </td>
                <td>{item?.result}</td>
                <td>
                  <div
                    className="learnings-wrapper"
                    style={{ whiteSpace: "pre-wrap", lineHeight: "1.2rem" }}
                  >
                    {item?.triggers}
                  </div>
                </td>

                <td>
                  <div
                    className="learnings-wrapper"
                    style={{ whiteSpace: "pre-wrap", lineHeight: "1.2rem" }}
                  >
                    {item?.learnings}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
