import React, { useCallback, useState } from "react";
import {
  CloseIcon,
  MoonIcon,
  ReportIcon,
  ShowDesignIcon,
  SunIcon,
} from "../../../Icons";
import { ReportFilterValues } from "../../../utilities";
import { Button } from "../Button/Button";
import SelectComponent from "../SelectComponent/SelectComponent";
import "./ExperimentFilters.scss";
import DateRangeComponent from "../DateRangeComponent/DateRangeComponent";
import Scrubber from "../Scrubber/Scrubber";
import SwitchButton from "../SwitchButton/SwitchButton";
import { CheckmarkIcon } from "react-hot-toast";

export default function ExperimentFilters({
  testFilterState,
  dispatchTestFilter = () => void 1,
  getReport = () => void 1,
  test = {},
}) {
  const [showScrubber, setShowScrubber] = useState(false);
  const createDimensionFilters = useCallback(
    (value, key, filterType, matchType) => {
      const filterObject = {
        filter: {
          fieldName: key,
        },
      };
      const newArr = [...testFilterState?.dimensionFilterExpression];
      const fieldName = newArr?.findIndex((f) => f?.filter?.fieldName === key);
      if (value?.value === "-reset") {
        if (fieldName > -1) {
          newArr.splice(fieldName, 1);
          return dispatchTestFilter({
            type: "dimensionFilterExpression",
            payload: newArr,
          });
        }
      }
      if (filterType === "string") {
        filterObject.filter.stringFilter = {
          matchType: matchType.toUpperCase(),
          value: value?.value,
        };
      }
      if (fieldName > -1) {
        newArr[fieldName] = filterObject;
      } else {
        newArr.push(filterObject);
      }
      dispatchTestFilter({
        type: "dimensionFilterExpression",
        payload: newArr,
      });
    },
    [testFilterState, dispatchTestFilter]
  );

  const selectDefaultValue = (arr, field, value) => {
    const checkValue = arr?.find((val) => val?.toLowerCase() === value);
    if (!checkValue) return null;
    return {
      label: checkValue,
      value: checkValue?.toLowerCase(),
    };
  };

  return (
    <div className="experiment-filters-wrapper section-pd border-bottom">
      <Scrubber
        showScrubber={showScrubber}
        setShowScrubber={setShowScrubber}
        images={{
          left: test?.controlimage,
          right: test?.variationimage,
        }}
      />
      <div className="experiment-filters-inner">
        <div className="flex align-center justify-space-between">
          <h1 className="m-0">Experiment Data</h1>
          <Button
            onClick={() => setShowScrubber(true)}
            icon={<ShowDesignIcon />}
            className="smart-button big active"
            text={`Compare`}
          />
        </div>
        <div className="filters-list flex flex-wrap align-center">
          {ReportFilterValues.map((item, index) => (
            <React.Fragment key={index}>
              <SelectComponent
                key={index}
                options={item?.values?.map((val) => {
                  return {
                    label: val,
                    value: val?.toLowerCase(),
                  };
                })}
                // isSearchable={true}
                filterOption={(options, input) => {
                  if (input) {
                    return options.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }
                  return options;
                }}
                onChange={(val) => {
                  createDimensionFilters(
                    val,
                    item?.fieldName,
                    item?.filterType,
                    item?.matchType
                  );
                }}
                value={selectDefaultValue(
                  item?.values,
                  item?.fieldName,
                  testFilterState?.dimensionFilterExpression?.find(
                    (f) => f?.filter?.fieldName === item?.fieldName
                  )?.filter?.stringFilter?.value
                )}
                icon={null}
                placeholder={item?.label}
              />
            </React.Fragment>
          ))}
          <DateRangeComponent
            testFilterState={testFilterState}
            dispatchTestFilter={dispatchTestFilter}
          />
          {/* <SelectComponent
            options={[
              {
                label: "Reset",
                value: null,
              },
              {
                label: "USD",
                value: "USD",
              },
              {
                label: "EUR",
                value: "EUR",
              },
              {
                label: "GBP",
                value: "GBP",
              },
            ]}
            onChange={(val) => {
              dispatchTestFilter({
                type: "selectedCurrency",
                payload: val?.value ? val : null,
              });
            }}
            value={testFilterState?.selectedCurrency}
            icon={null}
            placeholder={`Select Currency`}
          /> */}
          {/* <div className="switch-with-label inline-flex align-center">
            <div className="switch-btn">
              <input
                onChange={(e) => {
                  dispatchTestFilter({
                    type: "includeProducts",
                    payload: e.target.checked,
                  });
                }}
                type="checkbox"
                defaultChecked={testFilterState?.includeProducts}
                className="checkbox"
                id="checkboxinludeproducts"
              />
              <label
                htmlFor="checkboxinludeproducts"
                className="checkbox-label"
              >
                <div>On</div>
                <div>Off</div>
                <span className="ball"></span>
              </label>
            </div>
            <span>Fetch Products to Calculate Profit (BETA)</span>
          </div> */}

          <div className="switch-with-label inline-flex align-center">
            <div className="switch-btn">
              <input
                onChange={(e) => {
                  if (e.target.checked) {
                    createDimensionFilters(
                      {
                        label: "USD",
                        value: "usd",
                      },
                      "revenueCurrency",
                      "string",
                      "EXACT"
                    );
                  } else {
                    createDimensionFilters(
                      {
                        label: "-Reset",
                        value: "-reset",
                      },
                      "revenueCurrency",
                      "string",
                      "EXACT"
                    );
                  }
                  // dispatchTestFilter({
                  //   type: "interaDayTable",
                  //   payload: e.target.checked,
                  // });
                }}
                type="checkbox"
                defaultChecked={
                  testFilterState?.dimensionFilterExpression?.find(
                    (f) => f?.filter?.fieldName === "revenueCurrency"
                  )?.filter?.stringFilter?.value === "usd"
                }
                className="checkbox"
                id="revenueToUSD"
              />
              <label htmlFor="revenueToUSD" className="checkbox-label">
                <div>On</div>
                <div>Off</div>
                <span className="ball"></span>
              </label>
            </div>
            <span>Convert Revenue to USD </span>
          </div>

          <div className="switch-with-label inline-flex align-center">
            <div className="switch-btn">
              <input
                onChange={(e) => {
                  dispatchTestFilter({
                    type: "interaDayTable",
                    payload: e.target.checked,
                  });
                }}
                type="checkbox"
                defaultChecked={testFilterState?.interaDayTable}
                className="checkbox"
                id="checkboxintraday"
              />
              <label htmlFor="checkboxintraday" className="checkbox-label">
                <div>On</div>
                <div>Off</div>
                <span className="ball"></span>
              </label>
            </div>
            <span>Use events_intraday_ Table to fetch data </span>
          </div>

          <div className="switch-with-label inline-flex align-center">
            <div className="switch-btn">
              <input
                onChange={(e) => {
                  dispatchTestFilter({
                    type: "useRegexForUserId",
                    payload: e.target.checked,
                  });
                }}
                type="checkbox"
                defaultChecked={testFilterState?.useRegexForUserId}
                className="checkbox"
                id="checkboxuseregexforuserid"
              />
              <label
                htmlFor="checkboxuseregexforuserid"
                className="checkbox-label"
              >
                <div>On</div>
                <div>Off</div>
                <span className="ball"></span>
              </label>
            </div>
            <span>Use Regex to fetch user ID's</span>
          </div>
        </div>
        <div className="live-report-cta flex align-center justify-end">
          <Button
            className="smart-button big active"
            text={`Live Reports`}
            onClick={(e) => getReport()}
            icon={<ReportIcon />}
          />
        </div>
      </div>
    </div>
  );
}
