// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-factor-wrapper .security-boxes {
  display: flex;
  align-items: center;
  justify-content: center;
}
.two-factor-wrapper .security-boxes .vi__container div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #141414;
  color: #F7FFF7;
  font-family: "SF Pro Display";
  border: 1px solid #00FF8D;
  border-radius: 3px;
  font-size: 20px;
}
.two-factor-wrapper .security-boxes .vi__container div.vi__character--selected {
  outline: none;
}
.two-factor-wrapper .return-login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.two-factor-wrapper .return-login button {
  cursor: pointer;
  border-radius: 2px;
  background: #00FF8D;
  border: none;
  font-size: var(--medium-fontsize);
  padding: 3px 10px;
}
.two-factor-wrapper .two-factor-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.two-factor-wrapper .two-factor-qrcode img {
  width: 150px;
  height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Pages/TwoFactorAuth/Components/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AAEY;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,6BAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AAAhB;AACgB;EACI,aAAA;AACpB;AAII;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAFR;AAGQ;EACI,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,iCAAA;EACA,iBAAA;AADZ;AAII;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAFR;AAGQ;EACI,YAAA;EACA,aAAA;AADZ","sourcesContent":[".two-factor-wrapper{\n    .security-boxes{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        .vi__container{\n            div{\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                background: #141414;\n                color: #F7FFF7;\n                font-family: \"SF Pro Display\";\n                border:1px solid #00FF8D;\n                border-radius: 3px;\n                font-size: 20px;\n                &.vi__character--selected{\n                    outline: none;\n                }\n            }\n        }\n    }   \n    .return-login{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin-top: 20px;\n        button{\n            cursor: pointer;\n            border-radius: 2px;\n            background: #00FF8D;\n            border: none;\n            font-size: var(--medium-fontsize);\n            padding:3px 10px;\n        }\n    }\n    .two-factor-qrcode{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin-bottom: 30px;\n        img{\n            width: 150px;\n            height: 150px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
