import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import { useMyProfile } from "../../../Hooks/useMyProfile";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default ScrollToTop;
