// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-card {
  border: 2px solid var(--border-color);
  padding: var(--card-padding);
  border-radius: 2px;
}
.history-card svg path {
  fill: var(--desc-color);
}
.history-card .history-card-top {
  padding-bottom: var(--value12);
}
.history-card .history-card-top > div span {
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: normal;
  font-weight: var(--font-800);
  font-size: var(--value10);
  color: var(--desc-color-lite);
  margin-bottom: 4px;
}
.history-card .history-card-top > div p {
  font-size: var(--medium-fontsize);
  font-weight: var(--font-400);
  color: var(--desc-color);
}
.history-card .history-card-bottom {
  padding-top: var(--value12);
  column-gap: 40px;
}
.history-card .history-card-bottom > div {
  column-gap: var(--value12);
}
.history-card .history-card-bottom > div span {
  line-height: normal;
  font-weight: var(--font-400);
  font-size: var(--default-fontsize);
  color: var(--desc-color-lite);
}
.history-card .history-card-bottom button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/HistoryCard/HistoryCard.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,4BAAA;EACA,kBAAA;AACJ;AAIQ;EACI,uBAAA;AAFZ;AAKI;EACI,8BAAA;AAHR;AAKY;EACI,qBAAA;EACA,yBAAA;EACA,mBAAA;EACA,4BAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;AAHhB;AAKY;EACI,iCAAA;EACA,4BAAA;EACA,wBAAA;AAHhB;AAOI;EACI,2BAAA;EACA,gBAAA;AALR;AAMQ;EACI,0BAAA;AAJZ;AAKY;EACI,mBAAA;EACA,4BAAA;EACA,kCAAA;EACA,6BAAA;AAHhB;AAMQ;EACI,eAAA;AAJZ","sourcesContent":[".history-card{\n    border: 2px solid var(--border-color);\n    padding: var(--card-padding);\n    border-radius: 2px;\n    // max-width: 35%;\n    // min-width: 35%;\n    // width: 100%;\n    svg{\n        path{\n            fill: var(--desc-color);\n        }\n    }\n    .history-card-top{\n        padding-bottom: var(--value12);\n        &>div{\n            span{\n                letter-spacing: 0.4px;\n                text-transform: uppercase;\n                line-height: normal;\n                font-weight: var(--font-800);\n                font-size: var(--value10);\n                color: var(--desc-color-lite);\n                margin-bottom: 4px;\n            }\n            p{\n                font-size: var(--medium-fontsize);\n                font-weight: var(--font-400);\n                color: var(--desc-color);\n            }\n        }\n    }\n    .history-card-bottom{\n        padding-top: var(--value12);\n        column-gap: 40px;\n        &>div{\n            column-gap: var(--value12);\n            span{\n                line-height: normal;\n                font-weight: var(--font-400);\n                font-size: var(--default-fontsize);\n                color: var(--desc-color-lite);\n            }\n        }\n        button{\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
