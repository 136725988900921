import { useContext, useMemo, useState } from "react";
import { REPORTGRAPHTYPES } from "../../../constants";
import { Button } from "../Button/Button";
import "./ReportGraphs.scss";
import LineChartComponent from "../LineChartComponent/LineChartComponent";
import BarChartComponent from "../BarChartComponent/BarChartComponent";
import moment from "moment";
import { UserContext } from "../../../UserContext";
import NoData from "../NoData/NoData";

export default function ReportGraphs({
  timeSeriesData = null,
  probabilityOfWinner = null,
  isMabTest = false,
}) {
  const { state } = useContext(UserContext);
  const [selectedGraph, setSelectedGraph] = useState(REPORTGRAPHTYPES[0].value);
  const colors = ["255, 18, 18", "36, 102, 255", "255, 199, 0", "189, 0, 255"];

  const data = useMemo(() => {
    if (timeSeriesData) {
      return {
        labels: timeSeriesData?.labels?.map((label) =>
          moment(label, "YYYYMMDD").format("DD.MMM")
        ),
        datasets: timeSeriesData?.datasets?.map((dataset, index) => {
          return {
            data: [...dataset.timeSeries?.map((time) => time.value), 0],
            fill: true,
            borderColor: `rgba(${colors[index]}, 1)`,
            tension: 0.5,
            lineTension: 0.4,
            label: dataset.controlvariant,
            borderWidth: 1,
            order: index,
            pointRadius: 2,
            pointHoverRadius: 3,
            pointBorderColor: state?.theme === "light-theme" ? "#000" : "#fff",
            pointBackgroundColor:
              state?.theme === "light-theme" ? "#000" : "#fff",
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(10, 10, 10, 400);
              gradient.addColorStop(0, `rgba(${colors[index]}, 0.1)`);
              gradient.addColorStop(
                1,
                state?.theme === "light-theme" ? "#fff" : "#00000057"
              );
              return gradient;
            },
          };
        }),
      };
    }
    return {};
  }, [timeSeriesData, state]);

  const data2 = useMemo(() => {
    return {
      labels: probabilityOfWinner?.labels,
      datasets: [
        {
          indexAxis: "y",
          data: probabilityOfWinner?.data,
          fill: true,
          backgroundColor: (context) =>
            probabilityOfWinner?.data.map((dataset, index) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, `rgba(${colors[index]}, 0)`);
              gradient.addColorStop(1, `rgba(${colors[index]}, 1)`);
              return gradient;
            }),
          // backgroundColor: (context) => {
          //   const ctx = context.chart.ctx;
          //   const gradient = ctx.createLinearGradient(10, 10, 10, 400);
          //   gradient.addColorStop(0, `rgba(${colors[0]}, 0.1)`);
          //   gradient.addColorStop(1, `rgba(${colors[0]}, 1)`);
          //   return gradient;
          // },
          borderWidth: 0,
          borderRadius: 2,
          barThickness: 16,
          borderSkipped: false,
        },
      ],
      // datasets: probabilityOfWinner?.data.map((dataset, index) => {
      //   return {
      //     label: probabilityOfWinner?.labels[index],
      //     data: dataset,
      //     backgroundColor: (context) => {
      //       const ctx = context.chart.ctx;
      //       const gradient = ctx.createLinearGradient(10, 10, 10, 400);
      //       gradient.addColorStop(0, `rgba(${colors[index]}, 0.1)`);
      //       gradient.addColorStop(0, `rgba(${colors[index]}, 1)`);
      //       return gradient;
      //     },
      //   };
      // }),
    };
  }, [probabilityOfWinner]);
  if (isMabTest) return null;
  return (
    <div className="graphs-section section-pd-top">
      <h2>Graphs </h2>
      <p> Analyse change in Conversion rate over time with advanced Graphs.</p>
      <div className="graphs-wrapper">
        <div className="graphs-toggler flex align-center">
          {REPORTGRAPHTYPES.map(({ label, value }, index) => (
            <Button
              key={index}
              text={label}
              onClick={(e) => setSelectedGraph(value)}
              className={`smart-button big full-width ${
                selectedGraph === value ? "active" : ""
              }`}
            />
          ))}
        </div>
        <div>
          <LineChartComponent
            data={data}
            title=""
            timeSeriesData={timeSeriesData}
            style={
              selectedGraph === "timeseries"
                ? { display: "block" }
                : { display: "none" }
            }
          />

          <BarChartComponent
            title=""
            data={data2}
            probabilityOfWinner={probabilityOfWinner}
            colors={colors}
            style={
              selectedGraph === "probability"
                ? { display: "block" }
                : { display: "none" }
            }
          />
        </div>
      </div>
    </div>
  );
}
