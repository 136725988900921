import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "../Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GETBIGQUERYDATASETS } from "../../../Graphql/Queries";
import "./Datasets.scss";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
import NoData from "../NoData/NoData";
import { Splide, SplideSlide } from "@splidejs/react-splide";

export default function Datasets({
  client,
  viewId,
  myProfile,
  dataset,
  setDataset,
  splideOptions,
}) {
  const [datasets, setDatasets] = useState({
    loading: true,
  });

  const splideRef = useRef(null);

  useEffect(() => {
    const selectedView = document.querySelector(".thisisdataset");
    if (selectedView && splideRef.current) {
      setTimeout(() => {
        splideRef.current.go(Number(selectedView.getAttribute("data-index")));
      }, 1000);
    }
  }, [dataset, datasets]);

  const [getbigquerydatasets] = useLazyQuery(GETBIGQUERYDATASETS);
  useEffect(() => {
    const clientObj = myProfile?.client?.find(({ _id }) => _id === client);
    (async () => {
      try {
        const {
          data: { getBiQueryDataSets },
          loading,
          error,
        } = await getbigquerydatasets({
          variables: {
            projectId: clientObj?.bqClientId,
          },
        });
        setDataset(clientObj?.defaultDataSet || null);
        setDatasets({
          loading,
          error,
          data: getBiQueryDataSets,
        });
      } catch (error) {
        setDatasets({
          loading: false,
          error: true,
          data: null,
        });
      }
    })();
  }, [client, viewId, getbigquerydatasets, myProfile?.client, setDataset]);

  if (datasets?.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  if (datasets?.error)
    return (
      <NoData
        title="There was a problem connecting fetching datasets from Big Query."
        details={datasets?.error?.message}
      />
    );
  return (
    <div className="datasets-wrapper section-pd-top border-bottom">
      <h2> Data Sets: </h2>
      {/* <div className="tests-filter-wrapper inline-flex justify-center">
        {datasets?.data?.map((item, index) => (
          <Button
            key={index}
            style={{
              whiteSpace: "nowrap",
            }}
            onClick={(e) => {
              setDataset(item);
            }}
            className={`smart-button big full-width ${
              dataset === item ? `active neon-shadow` : ``
            }`}
          >
            BQ - {item}
          </Button>
        ))}
      </div> */}

      {/*  */}
      <div className="datasets-list defaultStates">
        <Splide aria-label="DataSets" options={splideOptions} ref={splideRef}>
          {datasets?.data?.map((item, index) => (
            <SplideSlide
              key={index}
              data-index={index}
              className={`${dataset === item ? `thisisdataset` : ``} `}
            >
              <Button
                style={{
                  whiteSpace: "nowrap",
                }}
                onClick={(e) => {
                  setDataset(item);
                }}
                className={`smart-button big full-width ${
                  dataset === item ? `active neon-shadow` : ``
                }`}
              >
                BQ - {item}
              </Button>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      {/*  */}
    </div>
  );
}
