import { useContext } from "react";
import TestsOverview from "./Components/TestsOverview/TestsOverview";
import ReportsList from "./Components/ReportsList/ReportsList";
import { UserContext } from "../../../UserContext";

export const Dashboard = () => {
  const { state } = useContext(UserContext);
  return (
    <div>
      {/* <AddRevPerMonth
        client={state.client}
        store={state.store}
        theme={state?.theme}
      /> */}

      <TestsOverview client={state.client} store={state.store} />

      <ReportsList client={state.client} store={state.store} />
    </div>
  );
};
