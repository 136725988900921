import { FilterIcon, ViewGridIcon, ViewListIcon } from "../../../Icons";
import { TESTRESULTSFILTERS } from "../../../constants";
import { Button } from "../Button/Button";

export default function TestFilterViewMode({
  state,
  dispatch,
  viewMode,
  setViewMode,
  filtersCount = {},
}) {
  return (
    <div className="results-filter-wrapper flex align-center justify-space-between flex-wrap">
      <div className="flex align-center">
        <div className="inline-flex align-center">
          <span>
            <FilterIcon />
          </span>
          <span>Filter:</span>{" "}
        </div>
        <div className="inline-flex">
          {TESTRESULTSFILTERS.map(({ label, value }, index) => (
            <Button
              key={index}
              text={`${label} ${
                filtersCount[value] ? `(${filtersCount[value]})` : ``
              }`}
              onClick={(e) => {
                if (dispatch) {
                  dispatch({ type: "testFilter", payload: value });
                }
              }}
              className={`smart-button small ${
                state.testFilter === value ? `active neon-shadow` : ``
              }`}
            />
          ))}
        </div>
      </div>
      <div className="flex align-center justify-end">
        <div className="inline-flex">
          <div className="inline-flex">View:</div>
        </div>
        <div
          className="inline-flex"
          onClick={(e) => {
            setViewMode(viewMode === "list" ? "grid" : "list");
          }}
        >
          {viewMode === "list" ? <ViewGridIcon /> : <ViewListIcon />}
        </div>
      </div>
    </div>
  );
}
