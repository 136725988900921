import { useQuery } from "@apollo/client";
import { GETCLIENTSFORTABLE } from "../../../Graphql/Queries";
import { useState } from "react";
import { Button } from "../Button/Button";
import { FilterIcon, ViewListIcon } from "../../../Icons";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
import ReportCard from "../ReportCard/ReportCard";
import { Pagination } from "../Pagination/Pagination";

const clientFiltersTabs = [
  {
    label: "Active Clients",
    key: "active",
    value: true,
  },
  {
    label: "Inactive Clients",
    key: "active",
    value: false,
  },
];

export default function ClientsList() {
  const [clientFilters, setClientFilters] = useState([{ active: true }]);
  const [page, setPage] = useState(1);
  const { data, loading, error } = useQuery(GETCLIENTSFORTABLE, {
    variables: {
      clientFilters: clientFilters?.filter(
        (item) => !Object.values(item).includes(null)
      ),
      page: page,
      size: 36,
    },
  });
  if (loading) return <ContentSkeleton />;
  if (error) return <div> {error?.message}</div>;
  const { clients } = data;
  return (
    <div className="section-pd">
      <h1>Clients List ({clients?.total})</h1>
      <div className="flex align-center justify-space-between flex-wrap default-margin-bottom">
        <div className="flex align-center default-column-gap">
          <div className="inline-flex align-center">
            <span>
              <FilterIcon />
            </span>
            <span>Filter:</span>{" "}
          </div>
          <div className="inline-flex default-column-gap">
            {clientFiltersTabs.map(({ label, value, key }, index) => (
              <Button
                key={index}
                text={`${label}`}
                onClick={() => {
                  setClientFilters([{ [key]: value }]);
                }}
                className={`smart-button small ${
                  clientFilters?.find((item) => item[key] === value)
                    ? `active neon-shadow`
                    : ``
                }`}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="reports-list-wrapper">
        <div className="reports-list-inner flex flex-wrap justify-space-between">
          {clients?.clients?.map((client, index) => (
            <ReportCard type="client" data={client} key={index} />
          ))}
        </div>
      </div>

      <Pagination
        page={page}
        size={36}
        setPage={setPage}
        totalcount={clients?.total}
      />
    </div>
  );
}
