import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      _id
      name
      email
      token
      redirectUrl
      role {
        _id
        name
      }
      name
    }
  }
`;

export const VERIFYTWOFACTOR = gql`
  mutation VerifyTwoFactor($authcode: String!) {
    verifyTwoFactor(authcode: $authcode) {
      _id
      token
    }
  }
`;

export const GETBIGQUERYREPORT = gql`
  mutation GetBigQueryData(
    $client: ID!
    $dateRange: DateRanges!
    $codebaseTestId: ID!
    $filters: reportFilters
    $dataSetId: String!
  ) {
    getBigQueryData(
      client: $client
      dateRange: $dateRange
      codebaseTestId: $codebaseTestId
      filters: $filters
      dataSetId: $dataSetId
    )
  }
`;

export const REFETCHREPORTHISTORY = gql`
  mutation RefetchTemporaryReportBigQuery(
    $id: ID!
    $dateRange: DateRanges!
    $otherFilters: JSON
  ) {
    refetchTemporaryReportBigQuery(
      _id: $id
      dateRanges: $dateRange
      otherFilters: $otherFilters
    )
  }
`;

export const CHANGEPASSWORD = gql`
  mutation ChangePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      _id
      token
    }
  }
`;

export const RESETPASSWORD = gql`
  mutation SendResetPasswordEmail($email: String!, $baseUrl: String) {
    sendResetPasswordEmail(email: $email, baseUrl: $baseUrl)
  }
`;

export const CHANGERESETPASSWORD = gql`
  mutation Mutation($password: String!, $userId: ID!, $token: String!) {
    resetPassword(password: $password, userId: $userId, token: $token) {
      email
    }
  }
`;

export const TOGGLEEXCLUDEREPORT = gql`
  mutation ToggleReportExclude($id: ID!, $value: Boolean) {
    toggleReportExclude(_id: $id, value: $value) {
      _id
      excludedFromCalculations
    }
  }
`;
