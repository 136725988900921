import { MoonIcon, SunIcon } from "../../../Icons";
import "./SwitchButton.scss";

export default function SwitchButton({ state, dispatch }) {
  return (
    <div className="switch-btn">
      <input
        onChange={(e) => {
          dispatch({
            type: "theme",
            payload: e.target.checked ? null : `light-theme`,
          });
        }}
        type="checkbox"
        defaultChecked={!state.theme}
        className="checkbox"
        id="checkbox"
      />
      <label htmlFor="checkbox" className="checkbox-label">
        <SunIcon />
        <MoonIcon type="dark" />
        <span className="ball">
          {!state.theme ? <MoonIcon /> : <SunIcon />}
        </span>
      </label>
    </div>
  );
}
