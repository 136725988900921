import NoData from "../../Components/NoData/NoData";
import { useLazyQuery } from "@apollo/client";
import { GETCVRREPORT } from "../../../Graphql/Queries";
import { useCallback, useContext, useEffect, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import { UserContext } from "../../../UserContext";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";

export const CVRReport = () => {
  const { state } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [getcvrdata] = useLazyQuery(GETCVRREPORT);
  const fetchData = useCallback(
    async (client) => {
      if (!client) return;
      try {
        const { data, loading } = await getcvrdata({
          fetchPolicy: "network-only",
          variables: {
            clientId: client,
          },
        });
        setData({
          link: data?.getCVRReport?.reportLink,
          loading,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [getcvrdata]
  );

  useEffect(() => {
    fetchData(state.client);
    return () => {
      setData(null);
    };
  }, [state, fetchData]);
  if (!state.client || data?.link === undefined)
    return (
      <NoData
        title="There was some error fetching details"
        details="Please retry wih your request or connect with the admin if the issue persists"
      />
    );
  if (!data || data.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="insights section-pd">
      <h1>CVR Report</h1>
      <div
        className="cvr-iframe-container"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            `<iframe
              src=${data.link}
              title="Looker Studio"
              width="100%"
              height="650px"
              sandbox="allow-scripts allow-same-origin"
            ></iframe>`,
            {
              ALLOWED_TAGS: ["iframe"],
            }
          ),
        }}
      />
    </div>
  );
};
