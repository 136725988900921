import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { TWOFACTORQRCODE } from "../../../Graphql/Queries";
import { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
import TwoFactorAuthComponent from "./Components";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import { Logo } from "../../../Icons";

export default function TwoFactorAuth() {
  const { twofactor } = useParams();
  const { loading, data, error } = useQuery(TWOFACTORQRCODE);
  if (loading) return <ContentSkeleton />;
  if (error || !twofactor || twofactor !== "two-factor") return "Error";
  return (
    <Suspense fallback={<Loader />}>
      <div className="login-wrapper">
        <div className="login-inner">
          <div className="login-card">
            <Logo />
            <TwoFactorAuthComponent twoFactor={data?.generateQRCode} />
          </div>
        </div>
      </div>
    </Suspense>
  );
}
