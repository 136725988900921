import React, { useContext, useMemo } from "react";
import "./Topbar.scss";
import SelectComponent from "../SelectComponent/SelectComponent";
import { StoreIcon, ViewIDIcon } from "../../../Icons";
import { UserContext } from "../../../UserContext";
import SwitchButton from "../SwitchButton/SwitchButton";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
import { logOut } from "../../../utilities";
import { Navigate, useNavigate } from "react-router-dom";

export default function Topbar({ state, dispatch, loading }) {
  const navigate = useNavigate();
  const { myProfile } = useContext(UserContext);
  const clients = useMemo(() => {
    if (!myProfile?.client) return [];
    const clients = myProfile?.client;
    if (clients.length === 1 && !state.client) {
      dispatch({ type: "client", payload: clients[0]._id });
    }
    if (
      clients.length > 0 &&
      !clients?.find(({ _id }) => _id === state.client)
    ) {
      dispatch({ type: "client", payload: clients[0]._id });
    }
    return clients?.map(({ _id, name }) => ({
      label: name,
      value: _id,
    }));
  }, [myProfile, dispatch, state.client]);

  // const properties = useMemo(() => {
  //   if (!myProfile?.client || !state.client) return [];
  //   const client = myProfile?.client.find(({ _id }) => _id === state.client);
  //   const properties = client?.gaproperties;
  //   if (clients.length === 1 && properties.length === 1 && !state.store) {
  //     dispatch({ type: "store", payload: properties[0].viewId });
  //     dispatch({ type: "platform", payload: properties[0].platform });
  //   }
  //   let results = properties?.map(({ viewId, propertyname, platform }) => ({
  //     label: platform + " - " + propertyname,
  //     value: viewId,
  //   }));

  //   return results;
  // }, [state, dispatch]);

  if (loading)
    return (
      <div className="conversion-topbar">
        <ContentSkeleton count={[1]} />
      </div>
    );
  const { name } = myProfile;
  return (
    <div className="conversion-topbar">
      <div className="store-selectors">
        <SelectComponent
          options={clients}
          onChange={(client) => {
            dispatch({ type: "client", payload: client.value });
          }}
          filterOption={(options, input) => {
            if (input) {
              return options.label.toLowerCase().includes(input.toLowerCase());
            }
            return options;
          }}
          value={clients.find((client) => client.value === state.client)}
          icon={<StoreIcon />}
          placeholder="Select Client"
        />
        {/* <SelectComponent
          options={properties}
          icon={<ViewIDIcon />}
          disabled={!state.client}
          onChange={(store) => {
            dispatch({ type: "store", payload: store.value });
            dispatch({
              type: "platform",
              payload: store?.label?.split("-")[0]?.trim(),
            });
          }}
          value={properties.find((store) => store.value === state.store)}
          placeholder="Select Store"
        /> */}
      </div>
      <div className="actions">
        <ul>
          {/* <li>Notifications</li> */}
          <li>
            <div>
              <SelectComponent
                options={[
                  {
                    label: name,
                    value: name?.toLowerCase(),
                  },
                  {
                    label: "Change Password",
                    value: "changepassword",
                  },
                  {
                    label: "Logout",
                    value: "logout",
                  },
                ]}
                icon={
                  <span className="user-initial inline-flex align-center justify-center">
                    {" "}
                    {name[0]}
                  </span>
                }
                onChange={(option) => {
                  switch (option.value) {
                    case "logout":
                      logOut({
                        returnUrl: "/login",
                      });
                      break;
                    case "changepassword":
                      navigate("/manage-password");
                      break;
                    default:
                      break;
                  }
                  // dispatch({ type: "store", payload: store.value });
                  // dispatch({
                  //   type: "platform",
                  //   payload: store?.label?.split("-")[0]?.trim(),
                  // });
                }}
                filterOption={(option) => option.value !== name?.toLowerCase()}
                value={{
                  label: name,
                  value: name?.toLowerCase(),
                }}
                isSearchable={false}
                placeholder="Select Store"
                customClass="user-select"
              />
            </div>
          </li>
          <li>
            <div className="switch-theme">
              <SwitchButton state={state} dispatch={dispatch} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
