// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-evalution-info {
  border-radius: 2px;
  border: 2px solid var(--border-color);
  padding: var(--card-padding);
  row-gap: var(--value16);
  margin-top: var(--default-margin-bottom);
}
.report-evalution-info .report-evalution-list {
  column-gap: var(--value8);
}
.report-evalution-info .report-evalution-list h3 {
  font-size: var(--xl-fontsize);
  color: var(--desc-color);
  font-weight: var(--font-800);
  margin-bottom: var(--value8);
}
.report-evalution-info .report-evalution-list h3 + div {
  margin-bottom: var(--value16);
}
.report-evalution-info .report-evalution-list p {
  font-size: var(--default-fontsize);
  color: var(--desc-color);
  font-weight: var(--font-400);
  line-height: normal;
}
.report-evalution-info .report-evalution-list p b {
  font-weight: var(--font-800);
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/ReportEvaluationInfo/ReportEvaluation.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qCAAA;EACA,4BAAA;EACA,uBAAA;EACA,wCAAA;AACJ;AAAI;EACI,yBAAA;AAER;AADQ;EACI,6BAAA;EACA,wBAAA;EACA,4BAAA;EACA,4BAAA;AAGZ;AAFY;EACI,6BAAA;AAIhB;AADQ;EACI,kCAAA;EACA,wBAAA;EACA,4BAAA;EACA,mBAAA;AAGZ;AAFY;EACI,4BAAA;AAIhB","sourcesContent":[".report-evalution-info{\n    border-radius: 2px;\n    border: 2px solid var(--border-color);\n    padding: var(--card-padding);\n    row-gap: var(--value16);\n    margin-top: var(--default-margin-bottom);\n    .report-evalution-list{\n        column-gap: var(--value8);\n        h3{\n            font-size: var(--xl-fontsize);\n            color: var(--desc-color);\n            font-weight: var(--font-800);\n            margin-bottom: var( --value8);\n            +div{\n                margin-bottom: var(--value16);\n            }\n        }\n        p{\n            font-size: var(--default-fontsize);\n            color: var(--desc-color);\n            font-weight: var(--font-400);\n            line-height: normal;\n            b{\n                font-weight: var(--font-800);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
