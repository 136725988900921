import React, { useEffect, useRef, useState } from "react";
import { checkAdditionalRevenue } from "../../../../../utilities";
import { Button } from "../../../../Components/Button/Button";
import "./BusinessCaseData.scss";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import parse from "html-react-parser";
import "@splidejs/react-splide/css";
import { CURRENCYSYMBOLSMAP } from "../../../../../constants";

export default function BusinessCaseData({
  topLineMetrics = {},
  // bottomLineMetrics,
  effectOnCLTV,
  next6MonthsMetrics,
  // futureMetrics,
  // businessCaseWithClv12M,
  businessData,
  // futureCLP,
  instanctEffect,
  secondaryEffect,
  threeMonthCohort,
  months,
  labels,
  currencyCode = "USD",
}) {
  const splideRef = useRef(null);
  const [activeLabel, setActiveLabel] = useState("businessData");

  const MetricCard = ({ title = "", data = [] }) => {
    return (
      <div className="metrics-card">
        <h2> {title}</h2>
        <ul className="flex flex-column">
          {data.map((item, index) => (
            <li
              key={index}
              className="inline-flex justify-space-between border-bottom"
            >
              <h3 className={item?.bold ? `weight800` : ``}>{item.title}</h3>
              <span className={item?.bold ? `weight800` : ``}>
                {item?.numberFormating ? (
                  checkAdditionalRevenue(
                    item.value,
                    null,
                    CURRENCYSYMBOLSMAP[currencyCode],
                    null,
                    item?.color,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )
                ) : (
                  <>
                    {CURRENCYSYMBOLSMAP[currencyCode]}
                    {item.value}
                  </>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const options = {
    type: "slide",
    perPage: 1,
    autoWidth: true,
    arrows: false,
    pagination: true,
  };

  return (
    <div className="business-case-validation-wrapper section-pd">
      <div className="business-case-validation-inner">
        <h1>Business Case Validation</h1>
        <div className="business-case-data flex flex-wrap justify-space-between">
          <MetricCard
            title="Top Line Metrics"
            data={[
              {
                title: "Revenue impact while Testing",
                value:
                  topLineMetrics?.revenueImpactWhileTesting?.toLocaleString(
                    "en-US"
                  ),
                numberFormating: true,
                color: false,
              },
              {
                title: "REALIZED Revenue impact after testing",
                value:
                  topLineMetrics?.revenueImpactAfterTesting?.toLocaleString(
                    "en-US"
                  ),
                numberFormating: true,
                color: false,
              },
              {
                title: "Realized Add CLTV",
                value: topLineMetrics?.realizedAddCLTV?.toLocaleString("en-US"),
                numberFormating: true,
                color: false,
              },
              {
                title: "Total Add Revenue",
                value: topLineMetrics?.totalAddRevenue?.toLocaleString("en-US"),
                bold: true,
                numberFormating: true,
                color: true,
              },
            ]}
          />
          <MetricCard
            title="Next 6M Metrics"
            data={[
              {
                title: "Add Transactions Impact",
                value:
                  next6MonthsMetrics?.additionalTransactionsImpact?.toLocaleString(
                    "en-US"
                  ),
                numberFormating: true,
                color: false,
              },
              {
                title: "Add CLTV",
                value: next6MonthsMetrics?.addCLTV?.toLocaleString("en-US"),
                numberFormating: true,
                color: false,
              },
              {
                title: "Total Add Revenue Next 6M",
                value:
                  next6MonthsMetrics?.totalAddRevenueNext6Month?.toLocaleString(
                    "en-US"
                  ),
                bold: true,
                numberFormating: true,
                color: true,
              },
            ]}
          />
          {/* <MetricCard
            title="Future Metrics"
            data={[
              {
                title: "Add CLTV",
                value: futureMetrics?.additionalCLTV,
              },
              {
                title: "Add CLP",
                value: futureMetrics?.additionalCLP,
              },
            ]}
          /> */}
        </div>

        <div className="business-case-data margin-bottom-auto">
          <div className="filter-labels flex flex-wrap justify-end">
            {labels?.map((label, index) => (
              <Button
                onClick={() => {
                  setActiveLabel(label.value);
                  splideRef.current.go(index);
                  var selectedkey = document.querySelectorAll(
                    `#${label.value}`
                  );
                  if (selectedkey?.length > 0) {
                    selectedkey.forEach((element) => {
                      element.classList.add("activeslide");
                      setTimeout(() => {
                        element.classList.remove("activeslide");
                      }, 1000);
                    });
                  }
                }}
                data-label={label.value}
                className={`smart-pill small label-index-${index} ${
                  activeLabel === label.value ? `active` : ``
                }`}
                key={index}
              >
                {index > 0 && <> {index}. </>}
                {label?.key}
              </Button>
            ))}
          </div>
          <div className="report-table-wrapper">
            <div className="report-data-table no-overflow flex">
              <div className="table-content-wrapper">
                <div className="table-heading">
                  <h3
                    style={{
                      opacity: 0,
                    }}
                  >
                    Revenue <br /> (BQ Data)
                  </h3>
                </div>
                <table>
                  <tbody>
                    <tr className="header">
                      <td>Month</td>
                    </tr>
                    {months?.map((month, index) => (
                      <tr key={index} className="data">
                        <td>{month}</td>
                      </tr>
                    ))}
                    <tr className="data total">
                      <td>Total</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Splide
                options={options}
                ref={splideRef}
                className="splidewithoverflow"
                onMoved={(slide, newIndex) => {
                  const element = document.querySelector(
                    `.filter-labels .label-index-${newIndex}`
                  );
                  if (element) {
                    setActiveLabel(element.getAttribute("data-label"));
                  }
                }}
              >
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={businessData?.label?.value}
                  >
                    <div className="table-heading">
                      <h3> {businessData?.label?.key}</h3>
                    </div>
                    <table>
                      <tbody>
                        {businessData?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>{businessData?.datalabels?.orders}</td>
                                <td>{businessData?.datalabels?.aov}</td>
                                <td>
                                  {parse(businessData?.datalabels?.revenue)}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>{data?.orders?.toLocaleString("en-US")}</td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.aov?.toLocaleString("en-US")}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.revenue?.toLocaleString("en-US")}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {businessData?.total?.orders?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessData?.total?.aov?.toLocaleString("en-US")}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessData?.total?.revenue?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={instanctEffect?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {instanctEffect?.label?.key}
                        <sup>1</sup>
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {instanctEffect?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {parse(
                                    instanctEffect?.datalabels
                                      ?.effectWhileTesting
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectWhileTesting?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              {/* <td>{data?.instantEffect}</td> */}
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {instanctEffect?.total?.effectWhileTesting?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          {/* <td>{instanctEffect?.total?.instantEffect}</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={secondaryEffect?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {" "}
                        {secondaryEffect?.label?.key} <sup>2</sup>
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {secondaryEffect?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {
                                    secondaryEffect?.datalabels
                                      ?.effectForNextMonth
                                  }
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.additionalTransactionNextMonth
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.effectFor6Months
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.realized6MEffect
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.realizedAddRevenue
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectForNextMonth?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              {/* <td>{data?.secondaryEffect}</td> */}
                              <td>
                                {data?.additionalTransactionNextMonth?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectFor6Months?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td>
                                {data?.realized6MEffect?.toLocaleString(
                                  "en-US"
                                )}{" "}
                                %
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.realizedAddRevenue?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {secondaryEffect?.total?.effectForNextMonth?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          {/* <td>{secondaryEffect?.total?.secondaryEffect}</td> */}
                          <td>
                            {secondaryEffect?.total?.additionalTransactionNextMonth?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {secondaryEffect?.total?.effectFor6Months?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          <td>
                            {secondaryEffect?.total?.realized6MEffect?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {secondaryEffect?.total?.realizedAddRevenue?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={effectOnCLTV?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {" "}
                        {effectOnCLTV?.label?.key}
                        <sup>3</sup>{" "}
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {effectOnCLTV?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {parse(
                                    effectOnCLTV?.datalabels?.additionalCLTV365d
                                  )}
                                </td>

                                <td>
                                  {parse(
                                    effectOnCLTV?.datalabels
                                      ?.percentageOfRealizedCLTV
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    effectOnCLTV?.datalabels?.realizedCLTV
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.additionalCLTV365d?.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td>
                                {data?.percentageOfRealizedCLTV?.toLocaleString(
                                  "en-US"
                                )}
                                %
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.realizedCLTV?.toLocaleString("en-US")}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {effectOnCLTV?.total?.additionalCLTV365d?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          {/* <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {threeMonthCohort?.total?.serviceCost?.toLocaleString("en-US")}
                          </td> */}
                          <td>
                            {/* {CURRENCYSYMBOLSMAP[currencyCode]} */}
                            {effectOnCLTV?.total?.percentageOfRealizedCLTV?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {effectOnCLTV?.total?.realizedCLTV?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>

          <div className="business-case-text">
            <ul className="flex flex-column">
              <li className="flex">
                <span>1 -</span>
                <span>Calculation: instant effect based on increased RPU</span>
              </li>

              <li className="flex">
                <span>2 - </span>
                <span>
                  Calculation: secondary effect based on increased CVR+AOV for 6
                  months
                </span>
              </li>

              <li className="flex">
                <span>3 - </span>
                <span>
                  Calculation: effect on customer live time value based on
                  increased transactions for 6 months
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
