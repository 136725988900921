import { Route, Routes } from "react-router-dom";
import React, { useMemo } from "react";
import AuthLayout from "../Views/Layouts/AuthLayout";
import { useMyProfile } from "../Hooks/useMyProfile";
import { PublicRoutes } from "./publicRoutes";
import { AuthRoutes } from "./authRoutes";
import DefaultLayout from "../Views/Layouts/DefaultLayout";

export default function RoutesLinks() {
  const routes = useMemo(() => {
    return [
      {
        layout: <DefaultLayout />,
        routes: PublicRoutes,
      },
      {
        layout: <AuthLayout />,
        routes: AuthRoutes,
      },
    ];
  }, []);
  return (
    <Routes>
      {routes.map((route, index) => (
        <React.Fragment key={index}>
          {route?.layout ? (
            <Route element={route.layout}>
              {route.routes.map((route, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                  {route.children &&
                    route.children.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                </React.Fragment>
              ))}
            </Route>
          ) : (
            <>
              {route.routes.map((route, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                  {route.children &&
                    route.children.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                </React.Fragment>
              ))}
            </>
          )}
        </React.Fragment>
      ))}
    </Routes>
  );
}
