import React, { useLayoutEffect } from "react";
import moment from "moment";
import { CloseIcon } from "../../../Icons";
import { REPORTHISTORYTYPES } from "../../../constants";
import "./HistoryCard.scss";
export default function HistoryCard({
  dispatch = () => void 1,
  state = {},
  _id,
  updatedAt,
  index,
  report,
  setReport,
  historySplideRef = () => void 1,
  refetchReportHistoryQuery = () => void 1,
  filters = "",
}) {
  useLayoutEffect(() => {
    if (state?.historySelection?.historyId === _id) {
      setTimeout(() => {
        historySplideRef.current.go(index);
      }, 1000);
    }
  }, [report]);

  const ReportHistoryTitle = ({ title }) => {
    title = title?.trim() === "" || !title ? "visitorType=All Users" : title;
    const rawTitle = title.split(";");
    const splitByRegex = /(=|!=|@|!@|^|$|==|!=|<|>|<=|>=)/g;
    const titlearr = rawTitle.map((t) => {
      return t.split(splitByRegex);
    });
    return (
      <>
        {titlearr.map((t, index) => {
          let segmentKey = t[0].split(/(?=[A-Z])/).join(" ");
          let segmentValue = t.slice(1).join("");
          segmentValue = segmentValue.replace(/=/g, "");
          return (
            <React.Fragment key={index}>
              <span className="inline-flex"> {segmentKey}:</span>
              <p
                style={{
                  textTransform: "capitalize",
                }}
              >
                {" "}
                {segmentValue}
              </p>
            </React.Fragment>
          );
        })}
      </>
    );
  };
  return (
    <div
      data-index={index}
      className={`history-card 
        ${state?.historySelection?.historyId === _id ? `active` : ``}
      `}
    >
      <div className="history-card-inner">
        <div className="history-card-top flex justify-space-between border-bottom">
          <div
            style={{
              textAlign: "left",
            }}
          >
            <ReportHistoryTitle title={filters} />
            {/* <span className="inline-flex"> Visitor Type:</span>
            <p> All Users</p> */}
          </div>
          <div
          // onClick={(e) => {
          //   dispatch({
          //     type: "historySelection",
          //     payload: {
          //       type: "deletehistory",
          //       historyId: _id,
          //     },
          //   });
          // }}
          >
            {/* <CloseIcon /> */}
          </div>
        </div>
        <div className="history-card-bottom flex justify-space-between align-center">
          <div>
            <span> {moment(updatedAt).fromNow()}</span>
          </div>
          <div className="inline-flex">
            {REPORTHISTORYTYPES.map((item, index) => (
              <button
                key={index}
                className={`smart-pill small ${
                  state?.historySelection?.type === item.value &&
                  state.historySelection?.historyId === _id
                    ? "active"
                    : ""
                }`}
                onClick={(_) => {
                  if (item.value === "history") {
                    setReport(report);
                  }
                  if (item.value === "refetch") {
                    refetchReportHistoryQuery(_id);
                  }
                  dispatch({
                    type: "historySelection",
                    payload: {
                      type: item.value,
                      historyId: _id,
                    },
                  });
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
