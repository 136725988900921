import VerificationInput from "react-verification-input";
import "./index.scss";
import { VERIFYTWOFACTOR } from "../../../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { logOut } from "../../../../utilities";
import { useMyProfile } from "../../../../Hooks/useMyProfile";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function TwoFactorAuthComponent({ twoFactor }) {
  const { myProfile } = useMyProfile();
  const navigate = useNavigate();
  const [verifyCode] = useMutation(VERIFYTWOFACTOR);

  const verifyTwoFactorCode = async (code) => {
    try {
      const { data } = await verifyCode({
        variables: {
          authcode: code,
        },
      });
      if (data) {
        localStorage.setItem("codebase_token", data?.verifyTwoFactor?.token);
        toast.success("User Authentication Successful");
        navigate("/dashboard");
      }
    } catch (error) {
      // console.log("Invalid Two Factor Code");
      toast.error("Invalid Code");
    }
  };

  const ReturnToLogin = ({ text = "Return to Login" }) => {
    return (
      <div className="return-login">
        <button
          onClick={(e) => {
            logOut({
              returnUrl: `/login`,
            });
          }}
        >
          {text}
        </button>
      </div>
    );
  };

  if (myProfile?.twoFactor) return <Navigate to="/dashboard" />;
  if (twoFactor?.enabled)
    return (
      <div className="two-factor-wrapper">
        <div className="security-boxes">
          <VerificationInput
            length={6}
            placeholder="_"
            autoFocus={true}
            onComplete={(e) => {
              verifyTwoFactorCode(e);
            }}
          />
        </div>
        <ReturnToLogin text="Return to Login" />
      </div>
    );
  return (
    <div className="two-factor-wrapper">
      <div className="two-factor-qrcode">
        <img src={twoFactor?.qrString} alt="Scan the Code" />
      </div>
      <div className="security-boxes">
        <VerificationInput
          length={6}
          placeholder=""
          autoFocus={true}
          onComplete={(e) => {
            verifyTwoFactorCode(e);
          }}
        />
      </div>
      <ReturnToLogin />
    </div>
  );
}
