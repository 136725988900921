import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Button } from "../Button/Button";
import "./DateRangeComponent.scss";
export default function DateRangeComponent({
  dispatchTestFilter = () => void 1,
  testFilterState = {},
}) {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="date-range-calendar-wrapper">
      <Button
        className="smart-button medium"
        onClick={(e) => {
          setShowCalendar(!showCalendar);
        }}
      >
        {moment(testFilterState?.selectionRange?.selection?.startDate).format(
          "DD. MMM YYYY"
        ) +
          " - " +
          moment(testFilterState?.selectionRange?.selection?.endDate).format(
            "DD. MMM YYYY"
          )}
      </Button>
      <div className="date-range-calendar-inner">
        {showCalendar && (
          <DateRangePicker
            showSelectionPreview={true}
            maxDate={new Date()}
            moveRangeOnFirstSelection={true}
            months={1}
            direction={`vertical`}
            ranges={[testFilterState?.selectionRange.selection]}
            className={``}
            onChange={(item) => {
              dispatchTestFilter({
                type: "selectionRange",
                payload: {
                  selection: {
                    startDate: item.selection.startDate,
                    endDate: item.selection.endDate,
                    key: "selection",
                  },
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
