import React from "react";
import { Outlet } from "react-router-dom";

export default function DefaultLayout() {
  return (
    <div className="conversion_full_page">
      <div className="conversion-public-layout">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}
