// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsla(153.18, 100%, 50%, 0.4);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.sidebar-skeleton .skeleton-text {
  width: 100%;
  height: 1.5rem;
  margin-bottom: 1.2rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/Skeletons/Skeletons.scss"],"names":[],"mappings":"AAAA;EACI,wDAAA;AACJ;;AAEE;EACE;IACE,8CAAA;EACJ;EACE;IACE,oCAAA;EACJ;AACF;AAEE;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;;AAGI;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;AAAN;;AAIE;EACE,UAAA;AADJ;;AAIE;EACE,UAAA;AADJ","sourcesContent":[".skeleton {\n    animation: skeleton-loading 1s linear infinite alternate;\n  }\n  \n  @keyframes skeleton-loading {\n    0% {\n      background-color: hsl(153.18deg 100% 50% / 40%);\n    }\n    100% {\n      background-color: hsl(200, 20%, 95%);\n    }\n  }\n  \n  .skeleton-text {\n    width: 100%;\n    height: 0.7rem;\n    margin-bottom: 0.5rem;\n    border-radius: 0.25rem;\n  }\n  .sidebar-skeleton{\n    .skeleton-text{\n      width: 100%;\n      height: 1.5rem;\n      margin-bottom: 1.2rem;\n      border-radius: 0.25rem;\n    }\n\n  }\n  .skeleton-text__body {\n    width: 75%;\n  }\n  \n  .skeleton-footer {\n    width: 30%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
