import React from "react";
import "./Search.scss";
import Input from "../Input/Input";
import { Button } from "../Button/Button";
import { SearchIcon } from "../../../Icons";
import { useDebouncedCallback } from "use-debounce";
export default function Search({
  inline = false,
  initialValue = "",
  dispatch,
  placeholder = "Search",
  bindOnClick = false,
}) {
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      dispatch({ type: "searchText", payload: value });
    },
    // delay in ms
    500
  );

  return (
    <div className="search-wrapper">
      <div
        className={`search-inner flex align-center  ${
          inline ? `search-inline justify-space-between` : ``
        }`}
      >
        <Input
          value={initialValue}
          placeholder={placeholder}
          onChange={(e) => {
            if (!bindOnClick) {
              debounced(e.target.value);
            }
          }}
          icon={<SearchIcon />}
        />
        <Button
          type="button"
          text={`Search`}
          onClick={(e) => {
            if (bindOnClick) {
              debounced(
                e.target.closest(".search-wrapper").querySelector("input").value
              );
            }
          }}
          className="smart-button big active"
          icon={<SearchIcon fill={`#070707`} opacity={`1`} />}
        />
      </div>
    </div>
  );
}
