// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-chart-wrapper .chart {
  padding: 0px 0px 0px 80px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/LineChartComponent/LineChartComponent.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;AADR","sourcesContent":["\n.line-chart-wrapper{\n    .chart{\n        padding: 0px 0px 0px 80px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
