import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import NoData from "../../Components/NoData/NoData";
import ClientsList from "../../Components/ClientsList/ClientsList";

export const Clients = () => {
  const { myProfile } = useContext(UserContext);

  if (!myProfile?.superadmin)
    return (
      <NoData
        title="You are not allowed to access this page."
        details="Please contact admin for more details."
      />
    );
  return <ClientsList />;
};
