import React from "react";
import parse from "html-react-parser";
import { InfoIcon } from "../../../Icons";
import "./ReportEvaluation.scss";
import moment from "moment";
export default function ReportEvaluation({ report = null }) {
  const List = ({ heading = "", description = "" }) => (
    <div className="report-evalution-list flex justify-content-center">
      <div>
        <InfoIcon />
      </div>
      <div>
        <h3> {heading}</h3>
        {parse(description)}
      </div>
    </div>
  );

  return (
    <div className="report-evalution-info flex flex-column">
      <List
        heading="Performance Results from:"
        description={
          moment(report.from).format("LL") +
          " - " +
          moment(report.to).format("LL")
        }
      />
      {report?.test?.hypothesis && (
        <List
          heading="Hypothesis:"
          description={`<div>
            ${report?.test?.hypothesis?.name}
            </div><p><b>Action: </b>
            ${report?.test?.hypothesis?.action}
            </p><p><b>Expectation: </b>
            ${report?.test?.hypothesis?.expectation}
            </p><p><b>Result: </b>
            ${report?.test?.hypothesis?.result}
            </p>`}
        />
      )}

      {report?.learnings && (
        <List
          heading="Learnings:"
          description={`<div>
            ${report?.learnings?.join(" ")}
      </div>`}
        />
      )}
    </div>
  );
}
