import { useLazyQuery } from "@apollo/client";
import ReportCard from "../../Components/ReportCard/ReportCard";
import { GETCLIENTLINKSANDSHEETS } from "../../../Graphql/Queries";
import { UserContext } from "../../../UserContext";
import { useCallback, useContext, useEffect, useState } from "react";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import NoData from "../../Components/NoData/NoData";

export const DocumentsLinks = () => {
  const { state } = useContext(UserContext);
  const [getclientlinks] = useLazyQuery(GETCLIENTLINKSANDSHEETS);
  const [data, setData] = useState({
    loading: true,
  });
  const getData = useCallback(async () => {
    if (!state.client) return;
    try {
      const { data, loading } = await getclientlinks({
        variables: {
          client: state.client,
          includePrivateDocs: false,
        },
      });
      setData({
        ...data?.getClientLinks,
        loading,
      });
    } catch (error) {}
  }, [state.client, getclientlinks]);

  useEffect(() => {
    getData();
  }, [getData]);

  // if (!state.client)
  //   return (
  //     <NoData
  //       title="Please Selector Store"
  //       details="We apologize, but there is currently no data to display. Please check back later as we continue to update our records. Thank you for your patience and understanding."
  //     />
  //   );

  // const { data, loading } = useQuery(GETCLIENTLINKSANDSHEETS, {
  //   variables: {
  //     client: state.client,
  //     includePrivateDocs: false,
  //   },
  // });

  if (data?.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="insights section-pd">
      <h1>Documents & Links</h1>
      <div className="reports-list-wrapper">
        {data?.links?.length === 0 ? (
          <NoData
            title="No Document Found"
            details="There is currently no document associated with your account."
          />
        ) : (
          <div className="reports-list-inner flex flex-wrap justify-space-between">
            {data?.links?.map((link, index) => (
              <ReportCard type="link" data={link} key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
