import { Navigate, useParams } from "react-router-dom";
import { useMyProfile } from "../../../Hooks/useMyProfile";
import LoginComponent from "./Components";
import { useCallback, useEffect } from "react";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import { useLazyQuery } from "@apollo/client";
import { CHECKTOKEN } from "../../../Graphql/Queries";
import toast from "react-hot-toast";

export const Login = () => {
  const { myProfile, loading } = useMyProfile();
  const [checklasttoken] = useLazyQuery(CHECKTOKEN);
  const { token } = useParams();
  const checkToken = useCallback(async () => {
    if (token) {
      try {
        const {
          data: { checkTokenV2 },
        } = await checklasttoken({
          variables: {
            token,
          },
        });
        if (checkTokenV2) {
          localStorage.setItem("codebase_token", checkTokenV2?.token);
          window.location.href = "/login/two-factor";
        }
      } catch (error) {
        toast.error(
          "There was an error with your last session. Please try again."
        );
        window.location.href = "/login";
      }
      // validate token
      // if valid, redirect to two-factor
      // if not, redirect to login
    }
  }, [token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  if (loading || token)
    return (
      <div className="login-wrapper">
        <div className="login-inner">
          <div className="login-card">
            <ContentSkeleton count={[1, 2]} />
          </div>
        </div>
      </div>
    );
  if (myProfile) return <Navigate to="/login/two-factor" />;
  return <LoginComponent />;
};
