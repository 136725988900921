import "./Skeletons.scss";

export default function SidebarSkeleton() {
  return (
    <>
      <div className="card__header full-width sidebar-skeleton">
        <h3 className="card__header header__title" id="card-title">
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </h3>
      </div>
    </>
  );
}
