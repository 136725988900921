import React, { Suspense, useReducer } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "../Components/Sidebar/Sidebar";
import "./AuthLayout.scss";
import Loader from "../Components/Loader/Loader";
import Topbar from "../Components/Topbar/Topbar";
import { UserContext } from "../../UserContext";
import NoData from "../Components/NoData/NoData";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import ContentSkeleton from "../Components/Skeletons/ContentSkeleton";
import { useMyProfile } from "../../Hooks/useMyProfile";
function reducer(state, action) {
  switch (action.type) {
    case "client":
      return (
        localStorage.removeItem("storeID"),
        localStorage.setItem("clientID", action.payload),
        { ...state, client: action.payload, store: null }
      );

    case "store":
      return (
        localStorage.setItem("storeID", action.payload),
        { ...state, store: action.payload }
      );
    case "platform":
      return (
        localStorage.setItem("platform", action.payload),
        { ...state, platform: action.payload }
      );
    case "sidebar":
      // console.log("YES", action.payload);
      sessionStorage.setItem("sidebar", action.payload);
      return {
        ...state,
        sidebar: action.payload,
      };
    case "theme":
      return (
        action.payload
          ? localStorage.setItem("themeselected", action.payload)
          : localStorage.removeItem("themeselected"),
        {
          ...state,
          theme: action.payload,
        }
      );
    default:
      return "Unrecognized command";
  }
}

const initialState = {
  client: localStorage.getItem("clientID") || null,
  store: localStorage.getItem("storeID") || null,
  platform: localStorage.getItem("platform") || "GA4",
  theme: localStorage.getItem("themeselected") || null,
};

export default function AuthLayout() {
  const { myProfile: isAuthenticated, loading, error } = useMyProfile();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sidebarState, dispatchSidebarState] = useReducer(reducer, {
    sidebar:
      sessionStorage.getItem("sidebar") &&
      sessionStorage.getItem("sidebar") === "false"
        ? false
        : true,
  });
  if (loading)
    return (
      <div className="conversion_full_page" data-theme={state.theme}>
        <div className="conversion-mainLayer">
          <Suspense fallback={<Loader />}>
            <Sidebar loading={loading} />
          </Suspense>
          <main>
            <Topbar state={state} dispatch={dispatch} loading={loading} />
            <div className="conversion-content">
              <ContentSkeleton />
            </div>
          </main>
        </div>
      </div>
    );
  if (!loading && !isAuthenticated) return <Navigate to="/login" />;
  if (error) return "Error";
  return (
    <UserContext.Provider
      value={{
        myProfile: isAuthenticated,
        state,
      }}
    >
      <div className="conversion_full_page" data-theme={state.theme}>
        <div
          className={`conversion-mainLayer ${
            sidebarState?.sidebar ? `open` : `close`
          }`}
        >
          <Suspense fallback={<Loader />}>
            <Sidebar
              sidebarState={sidebarState}
              dispatchSidebarState={dispatchSidebarState}
              superAdmin={isAuthenticated?.superadmin || false}
            />
          </Suspense>
          <main>
            <Topbar state={state} dispatch={dispatch} />
            <div className="conversion-content">
              {state.client ? (
                <Outlet />
              ) : (
                <NoData
                  title="Select Client Store"
                  details="We apologize, but there is currently no data to display.
                    Please select the store to continue. Thank you for your patience and understanding."
                />
              )}
            </div>
          </main>
        </div>
      </div>
    </UserContext.Provider>
  );
}
