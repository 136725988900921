// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-wrapper {
  margin-bottom: 18px;
}
.field-wrapper label {
  color: #FFF;
  font-size: var(--default-fontsize);
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  display: inline-flex;
}
.field-wrapper .input-wrapper {
  border-radius: 2px;
  border: 2px solid var(--border-color);
  background: var(--bg-color2);
  max-width: 388px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--value16);
  width: 100%;
}
.field-wrapper .input-wrapper input {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: 0;
  color: var(--desc-color);
}
.field-wrapper .fogotpassword {
  text-align: right;
  margin-top: 8px;
}
.field-wrapper .fogotpassword a {
  color: var(--color-green);
  font-size: var(--default-fontsize);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/Input/Input.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAAI;EACI,WAAA;EACA,kCAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;AAER;AAAI;EACI,kBAAA;EACA,qCAAA;EACA,4BAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,WAAA;AAER;AADQ;EACI,6BAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,wBAAA;AAGZ;AAAI;EACI,iBAAA;EACA,eAAA;AAER;AADQ;EACA,yBAAA;EACA,kCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,+BAAA;AAGR","sourcesContent":[".field-wrapper{\n    margin-bottom: 18px;\n    label{\n        color: #FFF;\n        font-size: var(--default-fontsize);\n        font-weight: 400;\n        line-height: normal;\n        margin-bottom: 8px;\n        display: inline-flex;\n    }\n    .input-wrapper{\n        border-radius: 2px;\n        border: 2px solid var(--border-color);\n        background: var(--bg-color2);\n        max-width: 388px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: var(--value16);\n        width: 100%;\n        input{\n            background-color: transparent;\n            width: 100%;\n            border: none;\n            outline: 0;\n            color: var(--desc-color);\n        }\n    }\n    .fogotpassword{\n        text-align: right;\n        margin-top: 8px;\n        a{\n        color: var(--color-green);\n        font-size: var(--default-fontsize);\n        font-style: normal;\n        font-weight: 300;\n        line-height: normal;\n        text-decoration-line: underline;\n        }\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
