// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-list {
  margin-top: var(--default-margin-bottom);
  column-gap: 1.06%;
  row-gap: var(--default-row-gap);
}
.filters-list .select-component {
  border: 1px solid var(--border-color);
  padding: 8px var(--value16);
  border-radius: 2px;
  min-width: 182px;
  background-color: var(--bg-color3);
}

.live-report-cta {
  margin-top: var(--default-margin-bottom);
}
.live-report-cta svg path {
  fill: rgb(7, 7, 7);
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/ExperimentFilters/ExperimentFilters.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,iBAAA;EACA,+BAAA;AACJ;AAAI;EACI,qCAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kCAAA;AAER;;AACA;EACI,wCAAA;AAEJ;AAAO;EACC,kBAAA;AAER","sourcesContent":[".filters-list{\n    margin-top: var(--default-margin-bottom);  \n    column-gap: 1.06%; \n    row-gap: var(--default-row-gap);\n    .select-component{\n        border: 1px solid var(--border-color);\n        padding: 8px var(--value16);\n        border-radius: 2px;\n        min-width: 182px;\n        background-color: var(--bg-color3);\n    }\n}\n.live-report-cta{\n    margin-top: var(--default-margin-bottom);\n    svg{\n       path{\n        fill: rgb(7, 7, 7);\n       }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
