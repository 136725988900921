import React from "react";
import "./LineChartComponent.scss";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import NoData from "../NoData/NoData";
export default function LineChartComponent({
  title = "Additional Revenue Per Month",
  filters = false,
  timeSeriesData = {},
  data,
  ...props
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgb(255 255 255 / 10%)",
        },
      },
      y: {
        // min: 0,
        // parse: false,
        // distribution: "linear",
        grid: {
          // drawOnChartArea: false,
          // tickWidth: 1,
          // tickLength: 0,
          // offset: true,
          color: "rgb(255 255 255 / 10%)",
          drawTicks: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        font: {
          size: 14,
        },
      },
      title: {
        display: false,
        text: `Conversions Rate Time Series`,
      },
      datalabels: {
        display: false,
        color: "#fff",
      },
    },
  };
  if (!timeSeriesData || Object.keys(timeSeriesData).length === 0) {
    return (
      <div {...props}>
        <NoData
          title="Timeseries distribution data not found."
          details="There was no data for timeseries graph distribution."
        />
      </div>
    );
  }
  return (
    <div className="line-chart-wrapper section-pd border-bottom" {...props}>
      <div className="line-chart-inner">
        {title && (
          <div className="chart-heading">
            <h2> {title}</h2>
          </div>
        )}
        <div className="chart">
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
}
