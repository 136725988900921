import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

export default function Scrubber({
  images = {},
  showScrubber,
  setShowScrubber = () => void 1,
}) {
  if (!images?.left || !images?.right) return null;
  return (
    <div
      className="scrubber-wrapper"
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.classList.contains("scrubber-wrapper")) {
          setShowScrubber(false);
        }
        // setShowScrubber(false)
      }}
      style={showScrubber ? { display: "flex" } : { display: "none" }}
    >
      <div className="scrubber-inner">
        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage src={images?.left} alt="Image one" />
          }
          itemTwo={
            <ReactCompareSliderImage src={images?.right} alt="Image two" />
          }
        />
      </div>
    </div>
  );
}
