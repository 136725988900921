import { Navigate } from "react-router-dom";
import TwoFactorAuth from "../Views/Pages/TwoFactorAuth/TwoFactorAuth";
import { Login } from "../Views/Pages/Login/Login";
import ResetPassword from "../Views/Pages/ManagePassword/ResetPassword";
import ReportDetails from "../Views/Pages/ReportDetails/ReportDetails";

export const PublicRoutes = [
  {
    name: "Home", // "Home" is the name of the route, it can be anything, it's just for reference
    path: "/",
    element: <Login />,
  },
  {
    name: "Login",
    path: "login",
    element: <Login />,
    children: [
      {
        name: "Two Factor Auth",
        path: "/login/:twofactor",
        element: <TwoFactorAuth />,
      },
      {
        name: "Reset Password",
        path: "/login/reset-password",
        element: <ResetPassword />,
      },
      {
        name: "Authenticate",
        path: "/login/authenticate/:token",
        element: <Login />,
      },
    ],
  },
  {
    name: "Change Password",
    path: "/change-password/:userId/:token",
    element: <ResetPassword />,
  },
  {
    name: "404",
    path: "*",
    element: <Navigate to="/404" />,
  },
  {
    name: "Report Details", // "Home" is the name of the route, it can be anything, it's just for reference
    path: "/sharereport/:id",
    element: <ReportDetails />,
  },
];
