import { useQuery } from "@apollo/client";
import { MYPROFILE } from "../Graphql/Queries";

// useMyProfile for getting profile
export function useMyProfile() {
  const { data, loading, error } = useQuery(MYPROFILE, {
    fetchPolicy: "network-only",
  });

  const roles = data?.myProfile?.role;
  const isadmin = roles?.some((rl) => rl?.name === "ADMIN");
  const isoptimizer = roles?.some((rl) => rl?.name === "OPTIMIZER");
  const isclient = roles?.some((rl) => rl?.name === "CLIENT");
  const isuser = roles?.some((rl) => rl?.name === "USER");
  const iseditor = roles?.some((rl) => rl?.name === "EDITOR");
  let entryRoute = "";
  if (isadmin || isoptimizer || isuser) {
    entryRoute = "/dashboard";
  } else if (isclient) {
    entryRoute = "/client-dashboard";
  } else if (iseditor) {
    entryRoute = "/editor-dashboard";
  } else {
    entryRoute = "/not-found";
  }
  return {
    myProfile: isclient ? data?.myProfile : null,
    loading,
    error,
    entryRoute,
  };
}
