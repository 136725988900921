import { useCallback, useEffect, useReducer, useState } from "react";
import {
  CURRENCYSYMBOLSMAP,
  REPORTSTYPES,
  TESTRESULTSFILTERS,
} from "../../../../../constants";
import { Button } from "../../../../Components/Button/Button";
import "./ReportsList.scss";
import Search from "../../../../Components/Search/Search";
import { FilterIcon, ViewGridIcon, ViewListIcon } from "../../../../../Icons";
import ReportCard from "../../../../Components/ReportCard/ReportCard";
import NoData from "../../../../Components/NoData/NoData";
import TestFilterViewMode from "../../../../Components/TestFilterViewMode/TestFilterViewMode";
import { useLazyQuery } from "@apollo/client";
import { GETREPORTSV2 } from "../../../../../Graphql/Queries";
import ContentSkeleton from "../../../../Components/Skeletons/ContentSkeleton";
import SelectComponent from "../../../../Components/SelectComponent/SelectComponent";
import moment from "moment";

const initialState = {
  activeFilter: "ended",
  searchText: "",
  testFilter: "all",
  timelineFilter: {
    year: null,
    month: null,
    date: null,
  },
};
function reducer(state, action) {
  switch (action.type) {
    case "activeFilter":
      return { ...state, activeFilter: action.payload, testFilter: "all" };
    case "searchText":
      return { ...state, searchText: action.payload };
    case "testFilter":
      return {
        ...state,
        testFilter: action.payload,
      };
    case "timelineFilter":
      return {
        ...state,
        timelineFilter: action.payload,
      };
    default:
      return "Unrecognized command";
  }
}

export default function ReportsList({ client, store }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getreportsdata] = useLazyQuery(GETREPORTSV2);
  const [testsData, setTestsData] = useState(null);
  const [viewMode, setViewMode] = useState("list");
  const getTestsData = useCallback(async () => {
    try {
      const {
        data: { getReportsV2 },
        loading,
      } = await getreportsdata({
        fetchPolicy: "cache-and-network",
        variables: {
          client,
          viewId: store,
          filters: [
            // {
            //   conclusion:
            //     state.testFilter !== "all" ? [state.testFilter] : null,
            // },
            {
              range: {
                from: moment(state.timelineFilter.date)
                  .startOf("month")
                  .toDate(),
                to: moment(state.timelineFilter.date).endOf("month").toDate(),
              },
            },
            {
              status: [state.activeFilter],
            },
            { name: state.searchText !== "" ? state.searchText : null },
          ],
        },
      });
      setTestsData({
        ...getReportsV2,
        loading,
      });
    } catch (error) {}
  }, [state, client, store]);

  useEffect(() => {
    getTestsData();
    return () => {};
  }, [getTestsData]);

  if (testsData?.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  let cards =
    state.testFilter === "all"
      ? testsData?.data
      : testsData?.data?.filter((test) => test.conclusion === state.testFilter);
  return (
    <div className="section-pd">
      <div className="tests-filter-wrapper flex align-center justify-space-between">
        {REPORTSTYPES.map(({ label, value }, index) => (
          <Button
            key={index}
            text={label}
            onClick={(e) => {
              dispatch({ type: "activeFilter", payload: value });
            }}
            className={`smart-button big full-width ${
              state.activeFilter === value ? `active neon-shadow` : ``
            }`}
          />
        ))}
      </div>
      <Search
        dispatch={dispatch}
        initialValue={state.searchText}
        placeholder="Search the Test"
        bindOnClick={true}
      />
      {(state.activeFilter === "ended" ||
        state.activeFilter === "timeline") && (
        <TestFilterViewMode
          state={state}
          dispatch={dispatch}
          filtersCount={testsData?.filtersCount ?? {}}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      )}

      {state.activeFilter === "timeline" && (
        <div className="report-timeline-wrapper">
          <div className="filters-list timeline-filters flex align-center">
            <SelectComponent
              options={testsData?.yearsRanges?.map((val) => ({
                label: val,
                value: val,
              }))}
              onChange={(val) => {
                dispatch({
                  type: "timelineFilter",
                  payload: {
                    year: val.value,
                    month: null,
                    date: null,
                  },
                });
              }}
              value={
                state.timelineFilter.year
                  ? {
                      label: state.timelineFilter.year,
                      value: state.timelineFilter.year,
                    }
                  : null
              }
              icon={null}
              placeholder={`Select Year`}
            />
            <div className="months-list full-width flex flex-wrap align-center">
              {testsData?.monthsRanges?.map((val, index) => (
                <span
                  onClick={(e) => {
                    dispatch({
                      type: "timelineFilter",
                      payload: {
                        year:
                          state.timelineFilter.year ??
                          moment(new Date()).format("YYYY"),
                        month: val,
                        date: moment(
                          `${
                            state.timelineFilter.year ??
                            moment(new Date()).format("YYYY")
                          }-${val}-01`,
                          "YYYY-MMMM-DD"
                        ),
                      },
                    });
                  }}
                  className={`inline-flex ${
                    state.timelineFilter.month === val ? "active" : ""
                  }`}
                  key={index}
                >
                  {val}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      {cards?.length === 0 ? (
        <NoData
          title="No Tests Found."
          details={`There are no ${state.activeFilter} tests.`}
        />
      ) : (
        <div className="reports-list-wrapper">
          <div className="reports-list-inner flex flex-wrap">
            {cards?.map((test, index) => (
              <ReportCard
                data={test}
                key={index}
                currency={CURRENCYSYMBOLSMAP[testsData?.currencyCode]}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
