// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sequential-results {
  border: 2px solid var(--border-color);
  margin-bottom: var(--default-margin-bottom);
  padding: var(--value12);
  column-gap: var(--default-row-gap);
  border-radius: 2px;
}
.sequential-results > div {
  color: var(--desc-color);
  font-size: var(--default-fontsize);
  text-wrap: nowrap;
}
.sequential-results > div span {
  color: var(--color-green);
}

.sequential-cards-wrapper {
  column-gap: 1.06%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Pages/Dashboard/Components/SequentialResults/SequentialResults.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,2CAAA;EACA,uBAAA;EACA,kCAAA;EACA,kBAAA;AACJ;AAAI;EACI,wBAAA;EACA,kCAAA;EACA,iBAAA;AAER;AADQ;EACI,yBAAA;AAGZ;;AACA;EACI,iBAAA;AAEJ","sourcesContent":[".sequential-results{\n    border: 2px solid var(--border-color);\n    margin-bottom: var(--default-margin-bottom);\n    padding: var(--value12);\n    column-gap: var(--default-row-gap);\n    border-radius: 2px;\n    &>div{\n        color: var(--desc-color);\n        font-size: var(--default-fontsize);\n        text-wrap: nowrap;\n        span{\n            color: var(--color-green);\n        }\n    }\n}\n.sequential-cards-wrapper{\n    column-gap: 1.06%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
