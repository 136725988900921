// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphs-section > p {
  color: var(--desc-color);
  font-size: var(--value24);
  line-height: normal;
}

.graphs-wrapper {
  margin-top: var(--default-margin-bottom);
  margin-bottom: var(--default-margin-bottom);
}
.graphs-wrapper .graphs-toggler {
  column-gap: 1.06%;
  row-gap: var(--default-row-gap);
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/ReportGraphs/ReportGraphs.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;EACA,yBAAA;EACA,mBAAA;AAAR;;AAGA;EACI,wCAAA;EACA,2CAAA;AAAJ;AACI;EACI,iBAAA;EACA,+BAAA;AACR","sourcesContent":[".graphs-section{\n    &>p{\n        color: var(--desc-color);\n        font-size: var(--value24);\n        line-height: normal;\n    }\n}\n.graphs-wrapper{\n    margin-top: var(--default-margin-bottom);\n    margin-bottom: var(--default-margin-bottom);\n    .graphs-toggler{\n        column-gap: 1.06%;\n        row-gap: var(--default-row-gap);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
