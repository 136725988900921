import { useQuery } from "@apollo/client";
import { ArrowNextIcon, ArrowUpIcon } from "../../../../../Icons";
import { Button } from "../../../../Components/Button/Button";
import "./PerformanceOverview.scss";
import { GETBUSINESSCASEVALIDATIONDATA } from "../../../../../Graphql/Queries";
import ContentSkeleton from "../../../../Components/Skeletons/ContentSkeleton";
import BusinessCaseData from "../BusinessCaseData/BusinessCaseData";
import React from "react";
import NoData from "../../../../Components/NoData/NoData";
import { useSearchParams } from "react-router-dom";
import { CURRENCYSYMBOLSMAP } from "../../../../../constants";

export default function PerformanceOverview({
  heading = "Performance Overview: ",
  data,
  loading,
  error,
  overview,
  setOverview,
}) {
  const PerformanceCard = ({
    changeIn = null,
    title = "Additional Revenue after Implementation",
    value = "17",
    symbol = "%",
    active = false,
    loading = true,
  }) => {
    if (loading)
      return (
        <div className={`p-card`}>
          <ContentSkeleton />
        </div>
      );
    return (
      <div className={`p-card ${active ? `active neon-shadow` : ``}`}>
        <div className="p-card-inner">
          <div className="p-card-top inline-flex align-center">
            <span className="change-in">
              <ArrowUpIcon />
            </span>

            <p> {title}</p>
          </div>
          <div className="p-card-bottom inline-flex align-center">
            {title === "Return on Invest" && value <= 0 ? (
              <>
                <span> - </span> <span> to be updated</span>
              </>
            ) : (
              <>
                <span>{symbol}</span> <span> {value} </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (error) return <NoData title="404 Not Found" details={error.message} />;
  return (
    <div className="conversion-performance">
      <div>
        <div className="p-card-flex flex">
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Total Additional Revenue",
              value:
                data?.getBusinessCaseValidationDataV2?.topLineMetrics?.totalAddRevenue?.toLocaleString(
                  "en-US"
                ),
              symbol:
                CURRENCYSYMBOLSMAP[
                  data?.getBusinessCaseValidationDataV2?.currencyCode
                ],
            }}
            // active={true}
          />
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Additional transactions due to CRO efforts",
              value:
                data?.getBusinessCaseValidationDataV2?.secondaryEffect?.total?.additionalTransactionNextMonth?.toLocaleString(
                  "en-US"
                ),
              symbol: "",
            }}
          />
          {/* <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Total Realized Profit",
              value:
                data?.getBusinessCaseValidationDataV2?.bottomLineMetrics?.totalRealizedCLP?.toLocaleString("en-US"),
              symbol:
                CURRENCYSYMBOLSMAP[
                  data?.getBusinessCaseValidationDataV2?.currencyCode
                ],
            }}
          />
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Return on Invest",
              value:
                data?.getBusinessCaseValidationDataV2?.topLineMetrics
                  ?.returnOnInvest,
              symbol: "%",
            }}
          /> */}
        </div>
        <div
          className={`performance-cta`}
          style={
            !overview
              ? {
                  display: "none",
                }
              : {}
          }
        >
          <Button
            className="smart-button medium"
            disabled={loading}
            style={
              data?.getBusinessCaseValidationDataV2?.months.length > 0
                ? {}
                : { display: "none" }
            }
            onClick={() => {
              setOverview(!overview);
              setTimeout(() => {
                document
                  .querySelector(".business-case-validation-wrapper ")
                  .scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
              }, 100);
            }}
            text={`See Details`}
            icon={<ArrowNextIcon />}
          />
        </div>
      </div>
    </div>
  );
}
