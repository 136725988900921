import { ConversionIcon } from "./Icons";

export const ValidateEmail = (mail) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
};

export const logOut = ({ returnUrl }) => {
  localStorage.removeItem("codebase_token");
  localStorage.removeItem("clientID");
  localStorage.removeItem("storeID");
  window.location.href = returnUrl || "/";
};

export const stringToNumberRemoveSpecialCharacters = (string) => {
  return string.replace(/[^0-9]/g, "");
};

export const checkConversionRate = (number, prefix = "", postfix = "") => {
  number = number.toString();
  number = number.replace(/[$,%]/g, "");
  number = isFinite(number) ? number : 0;
  let numberClass = "";
  if (number > 0) {
    numberClass = "green";
  }
  if (number < 0) {
    numberClass = "red";
  }
  return (
    <span className={numberClass}>
      <ConversionIcon />
      {number}
      {postfix}
    </span>
  );
};
export const checkConclusion = (conclusion) => {
  let conclusionClass = "";
  if (conclusion === "winner") {
    conclusionClass = "green";
  }
  if (conclusion === "no uplift") {
    conclusionClass = "red";
  }
  if (conclusion === "neutral") {
    conclusionClass = "yellow";
  }
  return (
    <span
      style={{
        textTransform: "capitalize",
      }}
      className={conclusionClass}
    >
      {conclusion}
    </span>
  );
};

export const checkAdditionalRevenue = (
  number,
  symbol = null,
  prefix = "",
  postfix = "",
  formatColor
) => {
  if (!number) {
    return 0;
  }
  number = number.toString();
  number = number.replace(/[$,%]/g, "");
  number = isFinite(number) ? number : 0;
  let numberPrefix = "";
  let numberClass = "";
  if (number > 0) {
    numberPrefix = "+";
    numberClass = "green";
  }
  if (number < 0) {
    numberClass = "red";
  }
  number = Number(number).toLocaleString();
  return (
    <span className={formatColor ? numberClass : ``}>
      {prefix}
      {symbol && numberPrefix}
      {number}
      {postfix}
    </span>
  );
};

export const checkStringLocalStrings = (
  stringValue,
  prefix = "",
  postfix = "",
  formatColor = {}
) => {
  if (!stringValue) {
    return 0;
  }
  stringValue = stringValue.toString();
  stringValue = stringValue.replace(/[$,%]/g, "");
  stringValue = isFinite(Number(stringValue)) ? stringValue : "0";
  let numberClass = "";
  if (stringValue > formatColor.threshold) {
    numberClass = "green";
  }
  if (stringValue < formatColor.threshold) {
    numberClass = "red";
  }
  stringValue = Number(stringValue).toLocaleString();
  return (
    <span className={numberClass}>
      {prefix}
      {stringValue}
      {postfix}
    </span>
  );
};

export const ReportFilterValues = [
  {
    label: "Browser",
    fieldName: "browser",
    filterType: "string",
    matchType: "EXACT",
    values: ["-Reset", "Chrome", "Firefox", "Safari", "Edge", "Opera", "Other"],
  },
  {
    label: "Visitor Type",
    fieldName: "visitorType",
    filterType: "string",
    matchType: "EXACT",
    values: ["-Reset", "New Visitor", "Returning Visitor"],
  },
  {
    label: "Device",
    fieldName: "deviceCategory",
    filterType: "string",
    matchType: "EXACT",
    values: ["-Reset", "Desktop", "Mobile", "Tablet"],
  },
  {
    label: "Operating System",
    fieldName: "operatingSystem",
    filterType: "string",
    matchType: "EXACT",
    values: [
      "-Reset",
      "Windows",
      "Macintosh",
      "Linux",
      "Chrome OS",
      "Android",
      "iOS",
      "Other",
    ],
  },
];
