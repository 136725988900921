import { useQuery } from "@apollo/client";
import { ArrowNextIcon, ArrowUpIcon } from "../../../../../Icons";
import { Button } from "../../../../Components/Button/Button";
import "./PerformanceOverview.scss";
import { GETBUSINESSCASEVALIDATIONDATA } from "../../../../../Graphql/Queries";
import ContentSkeleton from "../../../../Components/Skeletons/ContentSkeleton";
import BusinessCaseData from "../BusinessCaseData/BusinessCaseData";
import React from "react";
import NoData from "../../../../Components/NoData/NoData";
import { useSearchParams } from "react-router-dom";
import { CURRENCYSYMBOLSMAP } from "../../../../../constants";

export default function PerformanceOverview({
  heading = "Performance Overview: ",
  client,
  store,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [overview, setOverview] = React.useState(true);
  const { data, loading, error } = useQuery(GETBUSINESSCASEVALIDATIONDATA, {
    variables: {
      client,
      viewId: store,
      filters: [
        {
          sheetData: searchParams.get("sheetData") ?? "false",
        },
      ],
    },
  });
  const PerformanceCard = ({
    changeIn = null,
    title = "Additional Revenue after Implementation",
    value = "17",
    symbol = "%",
    active = false,
    loading = true,
  }) => {
    if (loading)
      return (
        <div className={`p-card`}>
          <ContentSkeleton />
        </div>
      );
    return (
      <div className={`p-card ${active ? `active neon-shadow` : ``}`}>
        <div className="p-card-inner">
          <div className="p-card-top inline-flex align-center">
            <span className="change-in">
              <ArrowUpIcon />
            </span>

            <p> {title}</p>
          </div>
          <div className="p-card-bottom inline-flex align-center">
            {title === "Return on Invest" && value <= 0 ? (
              <>
                <span> - </span> <span> to be updated</span>
              </>
            ) : (
              <>
                <span>{symbol}</span> <span> {value} </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (error) return <NoData title="404 Not Found" details={error.message} />;
  return (
    <div className="conversion-performance section-pd">
      <div
        style={
          !overview
            ? {
                display: "none",
              }
            : {}
        }
      >
        <h1>{heading}</h1>
        <div className="p-card-flex flex">
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Total Additional Revenue",
              value:
                data?.getBusinessCaseValidationDataV2?.topLineMetrics?.totalAddRevenue?.toLocaleString(),
              symbol:
                CURRENCYSYMBOLSMAP[
                  data?.getBusinessCaseValidationDataV2?.currencyCode
                ],
            }}
            active={true}
          />
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Additional transactions due to CRO efforts",
              value:
                data?.getBusinessCaseValidationDataV2?.secondaryEffect?.total?.additionalTransactionNextMonth?.toLocaleString(),
              symbol: "",
            }}
          />
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Total Realized Profit",
              value:
                data?.getBusinessCaseValidationDataV2?.bottomLineMetrics?.totalRealizedCLP?.toLocaleString(),
              symbol:
                CURRENCYSYMBOLSMAP[
                  data?.getBusinessCaseValidationDataV2?.currencyCode
                ],
            }}
          />
          <PerformanceCard
            loading={
              loading || !data?.getBusinessCaseValidationDataV2?.topLineMetrics
            }
            {...{
              title: "Return on Invest",
              value:
                data?.getBusinessCaseValidationDataV2?.topLineMetrics
                  ?.returnOnInvest,
              symbol: "%",
            }}
          />
        </div>
        <div className="performance-cta">
          <Button
            className="smart-button medium"
            disabled={loading}
            style={
              data?.getBusinessCaseValidationDataV2?.months.length > 0
                ? {}
                : { display: "none" }
            }
            onClick={() => setOverview(!overview)}
            text={`See Details`}
            icon={<ArrowNextIcon />}
          />
        </div>
      </div>
      <div
        style={
          overview
            ? {
                display: "none",
              }
            : {}
        }
      >
        <div className="performance-cta margin-top-auto">
          <Button
            className="smart-button medium"
            text={`Performance Overview`}
            disabled={loading}
            onClick={() => setOverview(!overview)}
            icon={
              <div
                className="inline-flex"
                style={{
                  transform: "rotate(180deg)",
                }}
              >
                <ArrowNextIcon />
              </div>
            }
          />
        </div>
        <BusinessCaseData {...data?.getBusinessCaseValidationDataV2} />
      </div>
    </div>
  );
}
