// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tests-filter-wrapper, .report-timeline-wrapper {
  column-gap: 1.8%;
  margin-bottom: var(--default-margin-bottom);
}
.tests-filter-wrapper button *, .report-timeline-wrapper button * {
  text-transform: none;
  font-weight: var(--font-600);
}

.results-filter-wrapper {
  padding: 0px 10px;
  margin-bottom: var(--default-margin-bottom);
}
.results-filter-wrapper > div {
  column-gap: 1.8%;
}
.results-filter-wrapper > div > div {
  column-gap: 1.08%;
}
.results-filter-wrapper > div > div button {
  min-width: max-content;
}
.results-filter-wrapper > div > div svg path {
  fill: var(--icons-color);
}

.reports-list-inner {
  row-gap: var(--default-row-gap);
  column-gap: 1.06%;
}

.timeline-filters {
  column-gap: 1.06%;
}
.timeline-filters .months-list {
  column-gap: 1.06%;
}
.timeline-filters .months-list span {
  cursor: pointer;
  padding: var(--tablehead-padding);
}
.timeline-filters .months-list span.active {
  background: var(--active-bg);
  color: var(--activeicons-color);
}
.timeline-filters .select-component .conversion-select__control {
  flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/Views/Pages/Dashboard/Components/ReportsList/ReportsList.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,2CAAA;AACJ;AACQ;EACI,oBAAA;EACA,4BAAA;AACZ;;AAIA;EACI,iBAAA;EACA,2CAAA;AADJ;AAEI;EACI,gBAAA;AAAR;AACQ;EACI,iBAAA;AACZ;AAAY;EACI,sBAAA;AAEhB;AACgB;EACI,wBAAA;AACpB;;AAKA;EACI,+BAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,iBAAA;AAFJ;AAGI;EACI,iBAAA;AADR;AAEQ;EACI,eAAA;EACA,iCAAA;AAAZ;AACY;EACI,4BAAA;EACA,+BAAA;AAChB;AAGI;EACI,iBAAA;AADR","sourcesContent":[".tests-filter-wrapper, .report-timeline-wrapper{\n    column-gap: 1.8%;\n    margin-bottom: var(--default-margin-bottom);\n    button{\n        *{\n            text-transform: none;\n            font-weight: var(--font-600);\n        }\n\n    }\n}\n.results-filter-wrapper{\n    padding: 0px 10px;\n    margin-bottom: var(--default-margin-bottom);\n    &>div{\n        column-gap: 1.8%;\n        &>div{\n            column-gap: 1.08%;\n            button{\n                min-width: max-content\n            }\n            svg{\n                path{\n                    fill: var(--icons-color);\n                }\n            }\n        }\n    }\n}\n.reports-list-inner{\n    row-gap: var(--default-row-gap);\n    column-gap: 1.06%;\n\n}\n.timeline-filters{\n    column-gap: 1.06%;\n    .months-list{\n        column-gap: 1.06%;\n        span{\n            cursor: pointer;\n            padding: var(--tablehead-padding);\n            &.active{\n                background: var(--active-bg);\n                color: var(--activeicons-color);\n            }\n        }\n    }\n    .select-component .conversion-select__control {\n        flex-wrap: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
