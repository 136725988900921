import React from "react";
import "./NoData.scss";
import { NoDataIcon } from "../../../Icons";
export default function NoData({
  title = "There was a problem connecting with GA4 account. Please contact admin for more details.",
  details = "7 PERMISSION_DENIED: User does not have sufficient permissions for this property. To learn more about Property ID, see https://developers.google.com/analytics/devguides/reporting/data/v1/property-id.",
}) {
  return (
    <div className="nodata-wrapper">
      <div className="nodata-inner flex align-center">
        <div className="nodata-icon">
          <NoDataIcon />
        </div>
        <div className="nodata-text">
          <h3>{title}</h3>
          <p>{details}</p>
        </div>
      </div>
    </div>
  );
}
