// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../Images/loginbackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-wrapper {
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: top center;
}
.login-wrapper.nobg {
  background: none;
  height: auto;
}
.login-wrapper .login-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.login-wrapper .login-card {
  border-radius: 2px;
  max-width: 460px;
  width: 100%;
  border: 2px solid #2C2C2C;
  background: rgba(20, 20, 20, 0.6);
  padding: 36px 36px;
}
.login-wrapper .login-card #acceleratedlogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  margin-bottom: 48px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Pages/Login/Components/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yDAAA;EACA,sBAAA;EACA,+BAAA;AACJ;AAAI;EACI,gBAAA;EACA,YAAA;AAER;AAGI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AADR;AAGI;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACI,yBAAA;EACA,iCAAA;EACA,kBAAA;AADZ;AAEY;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AAAhB","sourcesContent":[".login-wrapper{\n    height: 100vh;\n    background-image: url('../../../../Images/loginbackground.png');\n    background-size: cover;\n    background-position: top center;\n    &.nobg{\n        background: none;\n        height: auto;\n    }\n    // display: flex;\n    // align-items: center;\n    // justify-content: center;\n    .login-inner{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        height: 100%;\n    }\n    .login-card{\n        border-radius: 2px;\n        max-width: 460px;\n        width: 100%;\n            border: 2px solid #2C2C2C;\n            background: rgba(20, 20, 20, 0.60);\n            padding: 36px 36px;\n            #acceleratedlogo{\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                margin: 0px auto;\n                margin-bottom: 48px;\n            }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
