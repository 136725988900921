import { NavLink } from "react-router-dom";
import { AuthRoutes } from "../../../RoutesManager/authRoutes";
import { Logo, MinMaxIcon, MobileLogo } from "../../../Icons";
import SidebarSkeleton from "../Skeletons/SidebarSkeleton";

export const Sidebar = ({
  loading,
  sidebarState,
  dispatchSidebarState,
  superAdmin,
}) => {
  if (loading)
    return (
      <div
        className="conversion-sidebar"
        style={{
          padding: "1rem",
        }}
      >
        <SidebarSkeleton />
      </div>
    );
  return (
    <div className="conversion-sidebar">
      <div className="logo">
        <Logo />
      </div>
      <div className="logo mobile">
        <MobileLogo />
      </div>
      <ul>
        {AuthRoutes?.filter((route) => route.sidebar).map((route, index) => (
          <li key={index}>
            {route?.superadmin && !superAdmin ? null : (
              <NavLink to={route.path}>
                <span className="icon">{route.icon}</span>
                <span className="route">{route.name}</span>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
      <ul className="minmaxlist">
        <li>
          <NavLink
            to=""
            onClick={(e) => {
              e.preventDefault();
              dispatchSidebarState({
                type: "sidebar",
                payload: !sidebarState.sidebar,
              });
            }}
          >
            <span className="icon">
              <MinMaxIcon />
            </span>
            <span className="route">Minimize</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
