// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversion-topbar {
  padding: var(--card-padding);
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-color2);
  position: sticky;
  top: 0;
}
.conversion-topbar .store-selectors {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.conversion-topbar .actions ul {
  display: inline-flex;
  align-items: center;
  column-gap: var(--default-row-gap);
}
.conversion-topbar .actions ul li {
  color: #FFF;
  font-family: "SF Pro Display";
  font-size: var(--default-fontsize);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.conversion-topbar .icon .user-initial {
  background-color: var(--color-neon);
  height: 22px;
  width: 22px;
  font-size: var(--medium-fontsize);
  border-radius: 22px;
  font-weight: var(--font-700);
  color: var(--bg-color);
}`, "",{"version":3,"sources":["webpack://./src/Views/Components/Topbar/Topbar.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,aAAA;EACA,UAAA;EACA,mBAAA;EACA,8BAAA;EACA,4CAAA;EACA,kCAAA;EACA,gBAAA;EACA,MAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AAER;AACQ;EACI,oBAAA;EACA,mBAAA;EACA,kCAAA;AACZ;AAAY;EACI,WAAA;EACA,6BAAA;EACA,kCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEhB;AAGQ;EACI,mCAAA;EACA,YAAA;EACA,WAAA;EACA,iCAAA;EACA,mBAAA;EACA,4BAAA;EACA,sBAAA;AADZ","sourcesContent":[".conversion-topbar{\n    padding: var(--card-padding);\n    display: flex;\n    z-index: 2;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid var(--border-color);\n    background-color: var(--bg-color2);\n    position: sticky;\n    top: 0;\n    .store-selectors{\n        display: flex;\n        align-items: center;\n        column-gap: 30px;\n    }\n    .actions{\n        ul{\n            display: inline-flex;\n            align-items: center;\n            column-gap: var(--default-row-gap);\n            li{\n                color: #FFF;\n                font-family: \"SF Pro Display\";\n                font-size: var(--default-fontsize);\n                font-style: normal;\n                font-weight: 400;\n                line-height: normal;\n            }\n        }\n    }\n    .icon{\n        .user-initial{\n            background-color: var(--color-neon);\n            height: 22px;\n            width: 22px;\n            font-size: var(--medium-fontsize);\n            border-radius: 22px;\n            font-weight: var(--font-700);\n            color: var(--bg-color);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
