import { useContext } from "react";
import { Button } from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { UserContext } from "../../../UserContext";
import { useMutation } from "@apollo/client";
import { CHANGEPASSWORD } from "../../../Graphql/Mutations";
import toast from "react-hot-toast";
import { logOut } from "../../../utilities";

export default function ManagePassword() {
  const { myProfile } = useContext(UserContext);
  const [changepassword] = useMutation(CHANGEPASSWORD);
  const manageResetPassword = async (e) => {
    e.preventDefault();
    const form = e.target;
    const newPassword = form.newPassword.value?.trim();
    const confirmPassword = form.confirmPassword.value?.trim();
    const oldPassword = form.oldPassword.value?.trim();
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill all fields");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    if (oldPassword === newPassword) {
      toast.error("Old password and new password cannot be the same");
      return;
    }
    try {
      await changepassword({
        variables: {
          email: myProfile.email,
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
      });
      toast.success("Password changed successfully");
      logOut({
        returnUrl: "/login",
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="login-wrapper nobg">
      <div className="login-inner">
        <div className="login-card">
          <form onSubmit={manageResetPassword}>
            <Input component={true}>
              <div className="field-wrapper">
                <label> Old Password</label>
                <div className="input-wrapper">
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    autoComplete="off"
                    name="oldPassword"
                    autoCapitalize="off"
                  />
                </div>
              </div>
            </Input>
            <Input component={true}>
              <div className="field-wrapper">
                <label> New Password</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Enter your Password"
                    autoComplete="off"
                    name="newPassword"
                    autoCapitalize="off"
                  />
                </div>
              </div>
            </Input>
            <Input component={true}>
              <div className="field-wrapper">
                <label> Confirm Password</label>
                <div className="input-wrapper">
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    autoComplete="off"
                    name="confirmPassword"
                    autoCapitalize="off"
                  />
                </div>
              </div>
            </Input>
            <Button
              className="full-width smart-button big active "
              type="submit"
            >
              Reset Password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
