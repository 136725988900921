import React from "react";
import { Button } from "../Button/Button";
export default function ReportListItem({
  type = "report",
  keys = [],
  data = [],
  currency,
}) {
  return (
    <div>
      <div className="report-data-table oddevenpattern">
        <table>
          <tbody>
            <tr className="header">
              {keys.map((key, index) => (
                <td key={index}>{key}</td>
              ))}
            </tr>
            {data.map((item, index) => (
              <tr className="data" key={index}>
                <td>
                  {item?.controlvariant}{" "}
                  {item?.isMabTest && (
                    <Button className="smart-pill xsmall active">
                      MAB Test
                    </Button>
                  )}
                </td>
                <td>{!item?.isMabTest ? item?.conclusion : "NA"}</td>
                <td>{item?.segment}</td>

                <td>{item?.duration}</td>
                <td>
                  {!item?.isMabTest ? item?.changeInConversionRate : "NA"}
                </td>

                <td>{item?.additionalRevenue} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
