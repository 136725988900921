import { useNavigate, useParams } from "react-router-dom";
import "./ReportDetails.scss";
import { useQuery } from "@apollo/client";
import { GETREPORTV2 } from "../../../Graphql/Queries";
import NoData from "../../Components/NoData/NoData";
import { Button } from "../../Components/Button/Button";
import { ArrowNextIcon, ShowDesignIcon } from "../../../Icons";
import { checkConclusion } from "../../../utilities";
import ReportTable from "../../Components/ReportTable/ReportTable";
import ReportEvaluation from "../../Components/ReportEvaluationInfo/ReportEvaluation";
import VariantPerformance from "../../Components/VariantPerformance/VariantPerformance";
import ReportGraphs from "../../Components/ReportGraphs/ReportGraphs";
import ContentSkeleton from "../../Components/Skeletons/ContentSkeleton";
import { useContext, useMemo, useState } from "react";
import Scrubber from "../../Components/Scrubber/Scrubber";
import { UserContext } from "../../../UserContext";
export default function ReportDetails() {
  const { myProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showScrubber, setShowScrubber] = useState(false);
  const { data, loading, error } = useQuery(GETREPORTV2, {
    variables: {
      getReportV2Id: id,
    },
  });
  const isMabTest = useMemo(() => {
    if (
      data?.getReportV2[0]?.test?.tags?.some((tag) => tag?.name === "MAB Test")
    )
      return true;
    return false;
  }, [data]);

  if (loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  if (error) return <NoData title="Error" details="An error occurred" />;
  const report = data?.getReportV2[0];
  if (!report)
    return (
      <NoData
        title="No Report Found!"
        details="We couldn't find the report at the specified URL. It may have been moved to a different location or removed.<br/><br/> If you need assistance in locating the report, please contact the administrator for further support."
      />
    );
  return (
    <div>
      <div className="sharable-report-detail section-pd">
        {myProfile && (
          <div className="margin-top-auto">
            <Button
              className="smart-button medium active"
              text={`Go Back`}
              onClick={() => navigate(-1)}
              icon={
                <div
                  className="inline-flex"
                  style={{
                    transform: "rotate(180deg)",
                  }}
                >
                  <ArrowNextIcon />
                </div>
              }
            />
          </div>
        )}

        <div className="report-name-heading flex align-center justify-space-between flex-wrap">
          <h1 className="m-0">
            {report.test?.name || "Report Details"} ({report?.segmentName})
          </h1>
          <Button
            icon={<ShowDesignIcon />}
            onClick={() => setShowScrubber(true)}
            className="smart-button big active"
            text={`Show Design`}
          />
        </div>
        <Scrubber
          showScrubber={showScrubber}
          setShowScrubber={setShowScrubber}
          images={{
            left: report?.test?.controlimage,
            right: report?.test?.variationimage,
          }}
        />

        {report?.reportDetails?.conclusionFactorStatusAll && !isMabTest && (
          <div className="conclusion-factors-wrapper inline-flex align-center">
            {Object.keys(report?.reportDetails?.conclusionFactorStatusAll).map(
              (key) => (
                <Button className="smart-pill small" key={key}>
                  {key.replaceAll("Change in", "")}:{" "}
                  {checkConclusion(
                    report?.reportDetails?.conclusionFactorStatusAll[
                      key
                    ]?.toLowerCase()
                  )}
                </Button>
              )
            )}
          </div>
        )}

        <ReportEvaluation report={report} />

        <div className="report-table-wrapper border-bottom section-pd-bottom">
          {report?.reportLink && report?.reportLink !== "" && (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "1rem",
                }}
              >
                Report Was evaulated manually and the full report is available
                at the link below:
              </p>
              <div className="flex align-center justify-center">
                <Button
                  className="smart-button medium active"
                  text={`Show Full Report`}
                  onClick={() => {
                    window.open(report?.reportLink, "_blank");
                  }}
                  icon={null}
                />
              </div>
            </div>
          )}
          {!isMabTest && (
            <ReportTable
              total={[report?.reportDetails?.totalOfResults]}
              controlVariantData={report?.reportDetails?.result}
            />
          )}
        </div>
        <VariantPerformance
          calculatedResults={report?.reportDetails?.calculatedResults}
          isMabTest={isMabTest}
          concludingFactor={report?.reportDetails?.concludingFactor || "CR"}
        />
        <ReportGraphs
          timeSeriesData={report?.reportDetails?.timeSeriesData}
          probabilityOfWinner={report?.reportDetails?.probabilityOfWinner}
          isMabTest={isMabTest}
        />
      </div>
    </div>
  );
}
